import {connect} from '@gisatcz/ptr-state';
import Presentation from './presentation';
import Action from '../../../../../../state/Action';
import Select from '../../../../../../state/Select';

const mapStateToProps = (state, ownProps) => {
	const detailsViewKey = Select.router.getDetailsViewKey(state);
	return {
		detailsOpen: detailsViewKey === ownProps.viewKey,
	};
};

const mapDispatchToProps = () => {
	return dispatch => {
		return {
			openDetails: viewKey => {
				dispatch(Action.router.updateAppUrl(null, {detailsViewKey: viewKey}));
			},
			closeDetails: () => {
				dispatch(Action.router.updateAppUrl(null, {detailsViewKey: null}));
			},
		};
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
