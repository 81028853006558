import classnames from 'classnames';
import PropTypes from 'prop-types';
import {ExpandableLabelsContainer, IconTool} from '@gisatcz/visat-components';

import './style.scss';
import LayerLabel from '../LayerLabel';
import LayerControls from '../LayerControls';
import Tooltip from '../../../common/Tooltip';
import MapLegends from '../../../common/MapLegends';
import StyleBasedLegend from '../../../common/MapLegends/StyleBasedLegend';
const MapWrapper = ({
	activeMapKey,
	labelsOffset,
	labelsRight,
	layersState,
	mapKey,
	mapSetMapKeys,
	noRemove,
	removeMap,
	toolsLeft,
	children,
	layerTemplateKeysForLegend,
}) => {
	const wrapperClasses = classnames('visat-MapWrapper', {
		active: mapKey === activeMapKey,
		'one-map': mapSetMapKeys?.length === 1,
	});

	const labelsContainerClasses = classnames('visat-LayerLabelsContainer', {
		'is-right': labelsRight,
		'has-offset': labelsOffset,
	});

	const toolsContainerClasses = classnames('visat-MapTools', {
		'is-left': toolsLeft,
	});

	const legendClasses = classnames('visat-MapLegends', {
		'is-left': toolsLeft,
		'is-right': !toolsLeft,
	});

	//TODO -> invent some selector?
	const componentsByLayer = Object.keys(layerTemplateKeysForLegend).map(
		ltKey => ({
			layerTemplateKey: ltKey,
			legend: {
				component: StyleBasedLegend,
				props: {
					noData: false,
					style: layerTemplateKeysForLegend[ltKey].style,
					expandable: !!layerTemplateKeysForLegend[ltKey]?.expandable,
					...(layerTemplateKeysForLegend[ltKey]?.unit
						? {unit: layerTemplateKeysForLegend[ltKey]?.unit}
						: {}),
				},
			},
		})
	);

	return (
		<div className={wrapperClasses}>
			{layersState?.length ? (
				<ExpandableLabelsContainer className={labelsContainerClasses}>
					{layersState.map(layerState => (
						<LayerLabel
							key={layerState.key}
							layerState={layerState}
							mapKey={mapKey}
						/>
					))}
				</ExpandableLabelsContainer>
			) : null}
			<div className={toolsContainerClasses}>
				{!noRemove && mapSetMapKeys?.length > 1 ? (
					<IconTool
						className="visat-RemoveMapIcon"
						tooltip={{text: 'Remove map', position: 'left', component: Tooltip}}
						onClick={() => removeMap(mapKey)}
						floating
						medium
						icon="ri-close"
					/>
				) : null}
				<LayerControls opensBottom opensRight={toolsLeft} mapKey={mapKey} />
			</div>
			<MapLegends
				componentsByLayer={componentsByLayer}
				className={legendClasses}
				mapKey={mapKey}
			/>
			{children}
		</div>
	);
};

MapWrapper.propTypes = {
	activeMapKey: PropTypes.string,
	children: PropTypes.object,
	labelsRight: PropTypes.bool,
	labelsOffset: PropTypes.bool,
	layersState: PropTypes.array,
	mapKey: PropTypes.string,
	mapSetMapKeys: PropTypes.array,
	noRemove: PropTypes.bool,
	removeMap: PropTypes.func,
	toolsLeft: PropTypes.bool,
	layerTemplateKeysForLegend: PropTypes.object,
};

export default MapWrapper;
