import {useEffect} from 'react';
import PropTypes from 'prop-types';
import {Tag} from '@gisatcz/visat-components';

const ProductTag = ({active, darker, metadata, onMount, onUnmount}) => {
	useEffect(() => {
		if (typeof onMount === 'function') {
			onMount();
		}
		if (typeof onUnmount === 'function') {
			return onUnmount;
		}
	}, []);

	if (metadata) {
		const {data, isRecommendedTag, isCollectionTag, isDatasetTag} = metadata;
		if (data) {
			const {nameDisplay: name} = data;
			if (isRecommendedTag) {
				return <Tag active={active} darker={darker} small icon="ri-thumb-up" />;
			} else if (isDatasetTag) {
				return (
					<Tag active darker={darker} small icon="ri-datasets">
						{name}
					</Tag>
				);
			} else if (isCollectionTag) {
				return (
					<Tag active darker={darker} small icon="ri-collections">
						{name}
					</Tag>
				);
			} else {
				return (
					<Tag active={active} darker={darker} small>
						{name}
					</Tag>
				);
			}
		} else {
			return <Tag placeholder="light" />;
		}
	} else {
		return null;
	}
};

ProductTag.propTypes = {
	active: PropTypes.bool,
	darker: PropTypes.bool,
	metadata: PropTypes.object,
	onMount: PropTypes.func,
	onUnmount: PropTypes.func,
};

export default ProductTag;
