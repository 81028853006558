import {connect} from '@gisatcz/ptr-state';
import {Tooltip} from '@gisatcz/visat-components';
import Select from '../../../state/Select';

const mapStateToProps = state => {
	return {
		appIsDark: Select.router.getDarkModeActive(state),
	};
};

const mapDispatchToProps = () => {
	return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Tooltip);
