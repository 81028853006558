import {connect} from '@gisatcz/ptr-state';
import {createRoutes} from '../../../router';
import Select from '../../../state/Select';
import Presentation from './presentation';
import Action from '../../../state/Action';
const mapStateToProps = state => {
	const currentRouter = Select.router.getCurrent(state);
	const currentRoute = currentRouter.name;
	const parentPath = Select.router.getRouteParent(currentRoute, createRoutes());
	const viewKey = Select.router.getViewKey(state);
	const detailsOpen = Select.router.getDetailsOpen(state);

	return {
		parentPath: parentPath?.name,
		detailsOpen: detailsOpen,
		view: Select.views.getByKey(state, viewKey),
	};
};

const mapDispatchToProps = dispatch => {
	return {
		openDetails: () => {
			dispatch(Action.router.updateAppUrl(null, {detailsView: true}));
		},
		closeDetails: () => {
			dispatch(Action.router.updateAppUrl(null, {detailsView: null}));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
