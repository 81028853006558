import {Select as CommonSelect} from '@gisatcz/ptr-state';
import visat from './visat/selectors'; // eslint-disable-line
import router from './router/selectors';
import VisatApplicationsSelectors from './specific/VisatApplications/selectors';
import applications from '../data/applications';
import {defaultLinks} from '../constants/app';

const getLinks = () => {
	return window?.runtimeConfig?.links || defaultLinks;
};

const getApplications = () => {
	return applications.map(app => {
		if (app.key === 'sdg') {
			return {
				...app,
				data: {
					...app.data,
					url: getLinks().sdg,
				},
			};
		}

		if (app.key === 'unhab') {
			return {
				...app,
				data: {
					...app.data,
					url: getLinks().unhab,
				},
			};
		}

		if (app.key === 'citydatacube') {
			return {
				...app,
				data: {
					...app.data,
					url: getLinks().xcube,
				},
			};
		}
		return app;
	});
};

export default {
	getLinks,
	getApplications,

	...CommonSelect,
	visat,
	router,
	specific: {
		visatApplications: VisatApplicationsSelectors,
	},
};
