import PropTypes from 'prop-types';

import DatasetCatalog from './DatasetCatalog';
import ApplicationCatalog from './ApplicationCatalog';
import CollectionCatalog from './CollectionCatalog';
import IndexCenteredWrap from '../common/IndexCenteredWrap';
import './style.scss';
import {screens} from '../../../constants/app';

const getScreenComponent = name => {
	switch (name) {
		case screens['applications']:
			return <ApplicationCatalog />;
		case screens['datasets']:
			return <DatasetCatalog />;
		case screens['collections']:
			return <CollectionCatalog />;
	}
};

const IndexBody = ({activeScreen}) => {
	return (
		<div className="visat-IndexBody">
			<IndexCenteredWrap>
				<div className="visat-IndexBody-content">
					{activeScreen ? getScreenComponent(activeScreen) : null}
				</div>
			</IndexCenteredWrap>
		</div>
	);
};

IndexBody.propTypes = {
	activeScreen: PropTypes.string,
};

export default IndexBody;
