import {connect} from '@gisatcz/ptr-state';
import Presentation from './presentation';
import {utils} from '@gisatcz/ptr-utils';
import Action from '../../../../state/Action';
import {datasetTagsIndex} from '../../../../constants/app';

const mapStateToProps = () => {
	return {};
};

const mapDispatchToProps = () => {
	const componentId = `applicationsCatalog_tags_${utils.uuid()}`;
	return dispatch => {
		return {
			onMount: () => {
				dispatch(
					Action.tags.useIndexed(
						datasetTagsIndex.filterByActive,
						datasetTagsIndex.filter,
						datasetTagsIndex.order,
						datasetTagsIndex.start,
						datasetTagsIndex.length,
						componentId
					)
				);
			},
			onUnmount: () => {
				dispatch(Action.tags.useIndexedClear(componentId));
			},
		};
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
