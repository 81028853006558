import {connect} from '@gisatcz/ptr-state';
import Select from '../../../../state/Select';
import Action from '../../../../state/Action';
import {mapSetKey} from '../../../../constants/app';
import Presentation from './presentation';

const mapStateToProps = state => {
	return {
		maps: Select.maps.getMapSetMapKeys(state, mapSetKey),
		mapMode: Select.components.get(state, 'Map', 'mode'),
	};
};

const mapDispatchToProps = dispatch => {
	return {
		setMapMode: mode => {
			dispatch(Action.components.set('Map', 'mode', mode));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
