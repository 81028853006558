import PropTypes from 'prop-types';
import {Tag} from '@gisatcz/visat-components';

const ProductTag = ({active, metadata}) => {
	if (metadata) {
		const {data} = metadata;
		if (data) {
			const {nameDisplay: name} = data;
			return (
				<Tag active={active} darker small>
					{name}
				</Tag>
			);
		} else {
			return <Tag placeholder="light" />;
		}
	} else {
		return <Tag placeholder="light" />;
	}
};

ProductTag.propTypes = {
	active: PropTypes.bool,
	darker: PropTypes.bool,
	metadata: PropTypes.object,
};

export default ProductTag;
