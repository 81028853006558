import IndexCenteredWrap from '../common/IndexCenteredWrap';
import IndexNav from './IndexNav';
import IndexTools from './IndexTools';
import './style.scss';

const IndexHeader = () => {
	return (
		<div className={'visat-IndexHeader'}>
			<IndexCenteredWrap>
				<div className="visat-IndexHeader-content">
					<h1 className="visat-IndexTitle">visat</h1>
					<IndexNav />
					<IndexTools />
				</div>
			</IndexCenteredWrap>
		</div>
	);
};

IndexHeader.propTypes = {};

export default IndexHeader;
