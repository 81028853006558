import {useEffect} from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {Icon, NothingFiltered} from '@gisatcz/visat-components';
import DatasetCard from './DatasetCard';
import './style.scss';

const DatasetList = ({
	activeFilterTagKeys,
	addDatasetMode,
	datasets,
	viewFilter,
	onViewFilterChanged,
	onUnmount,
}) => {
	useEffect(() => {
		return onUnmount;
	}, []);

	useEffect(() => {
		onViewFilterChanged(viewFilter);
	}, [viewFilter]);

	let countContent = `Loading datasets...`;
	let datasetsContent, nothingFiltered;

	if (activeFilterTagKeys?.length || datasets) {
		countContent = `Showing ${datasets?.length} ${
			datasets?.length === 1 ? 'dataset' : 'datasets'
		}`;

		if (datasets?.length) {
			datasetsContent =
				datasets &&
				datasets.map(dataset => {
					return (
						<DatasetCard
							addDatasetMode={addDatasetMode}
							key={dataset.key}
							viewKey={dataset.key}
							tagKeys={dataset.data?.tagKeys}
							name={dataset.data?.nameDisplay}
							description={dataset.data?.description}
						/>
					);
				});
		} else {
			nothingFiltered = true;
			datasetsContent = <NothingFiltered />;
		}
	} else {
		datasetsContent = (
			<>
				<DatasetCard placeholder />
				<DatasetCard placeholder />
				<DatasetCard placeholder />
				<DatasetCard placeholder />
				<DatasetCard placeholder />
				<DatasetCard placeholder />
				<DatasetCard placeholder />
				<DatasetCard placeholder />
				<DatasetCard placeholder />
			</>
		);
	}

	const bodyClasses = classnames('visat-DatasetList-body', {
		'nothing-filtered': nothingFiltered,
	});

	return (
		<div className="visat-DatasetList">
			<div className="visat-DatasetList-header">
				<div className="visat-DatasetList-count">{countContent}</div>
				<div className="visat-DatasetList-orders">
					<div>From A to Z</div>
					<Icon icon="ri-chevron-down" />
				</div>
			</div>
			<div className={bodyClasses}>{datasetsContent}</div>
		</div>
	);
};

DatasetList.propTypes = {
	addDatasetMode: PropTypes.bool,
	activeFilterTagKeys: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
	datasets: PropTypes.array,
	viewFilter: PropTypes.object,
	onViewFilterChanged: PropTypes.func,
	onUnmount: PropTypes.func,
};

export default DatasetList;
