import {connect} from '@gisatcz/ptr-state';
import Select from '../../../state/Select';
import Presentation from './presentation';

const mapStateToProps = (state, ownProps) => {
	const viewKey = Select.router.getDetailsViewKey(state);
	const view = ownProps.view || Select.views.getByKey(state, viewKey);
	return {
		view,
	};
};

const mapDispatchToProps = () => {
	return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
