import {Action as CommonAction} from '@gisatcz/ptr-state';
import {Select as CommonSelect} from '@gisatcz/ptr-state';

function updateMapSetActiveMapView(mapSetKey, place) {
	return (dispatch, getState) => {
		if (place?.pantherMapView) {
			const activeMapSetMapKey = CommonSelect.maps.getMapSetActiveMapKey(
				getState(),
				mapSetKey
			);
			dispatch(
				CommonAction.maps.updateMapAndSetView(
					activeMapSetMapKey,
					place?.pantherMapView
				)
			);
		}
	};
}

export default {
	updateMapSetActiveMapView,
};
