import {createSelector} from 'reselect';
import {Select as CommonSelect} from '@gisatcz/ptr-state';

/**
 * Get tag metadata by key
 * @param tagKey {string}
 * @return {Object}
 */
const getByKey = createSelector(
	[
		CommonSelect.tags.getByKey,
		state => CommonSelect.app.getConfiguration(state, 'tags.isRecommended'),
		state => CommonSelect.app.getConfiguration(state, 'tags.isDataset'),
		state => CommonSelect.app.getConfiguration(state, 'tags.isCollection'),
	],
	(tag, recommendedTagKey, datasetTagKey, collectionTagKey) => {
		const isRecommendedTag = tag?.key === recommendedTagKey;
		const isCollectionTag = tag?.key === collectionTagKey;
		const isDatasetTag = tag?.key === datasetTagKey;

		if (tag) {
			return {
				...tag,
				isRecommendedTag,
				isCollectionTag,
				isDatasetTag,
			};
		} else {
			return {};
		}
	}
);

/**
 * Get tag metadata for dataset card
 * @param tagKey {string}
 * @return {Object}
 */
const getByKeyForDatasetCard = createSelector([getByKey], metadata => {
	if (metadata?.isDatasetTag) {
		return null;
	} else {
		return metadata;
	}
});

/**
 * Get tag metadata for collection card
 * @param tagKey {string}
 * @return {Object}
 */
const getByKeyForCollectionCard = createSelector([getByKey], metadata => {
	if (metadata?.isCollectionTag) {
		return null;
	} else {
		return metadata;
	}
});

export default {
	getByKey,
	getByKeyForDatasetCard,
	getByKeyForCollectionCard,
};
