import {createRoot, hydrateRoot} from 'react-dom/client';
import {Provider} from '@gisatcz/ptr-state';
import {isServer} from '@gisatcz/ptr-core';

import createStore from './state/Store';
import {AppWrapper} from './app';
const {store} = createStore();

const ConnectedApp = () => (
	<Provider store={store}>
		<AppWrapper />
	</Provider>
);

function renderApp() {
	const rootEl = document.getElementById('root');
	if (isServer || (rootEl.hasChildNodes() && false)) {
		hydrateRoot(rootEl, <ConnectedApp />);
	} else {
		const root = createRoot(rootEl);
		root.render(<ConnectedApp />);
	}
}

renderApp();
