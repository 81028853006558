import {connect} from '@gisatcz/ptr-state';
import Select from '../../state/Select';

import Presentation from './presentation';
import {mapSetKey} from '../../constants/app';
import Action from '../../state/Action';

import './style.scss';

const mapStateToProps = state => {
	const timelineLayers = Select.visat.productViewer.getTimelineLayers(state);
	const periodLimit = Select.visat.productViewer.getTimelinePeriod(state);
	return {
		timelineLayers,
		activeMapKey: Select.maps.getMapSetActiveMapKey(state, mapSetKey),
		periodLimit,
		isCollapsed: Select.components.get(state, 'Timeline', 'collapsed'),
	};
};

const mapDispatchToProps = () => {
	return dispatch => {
		return {
			onCollapse: collapsed => {
				dispatch(Action.components.set('Timeline', 'collapsed', collapsed));
			},
		};
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
