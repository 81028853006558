import PropTypes from 'prop-types';
import classnames from 'classnames';
import {Icon} from '@gisatcz/visat-components';
import Link from '../../../common/Link';
import './style.scss';
import {getRouter} from '../../../../router';

const IndexNavItem = ({active, disabled, icon, children, path}) => {
	const classes = classnames('visat-IndexNavItem', {
		'is-active': active,
		'is-disabled': disabled,
	});

	return (
		<Link
			tabIndex={0}
			classes={classes}
			name={path}
			router={getRouter()}
			paramsFilter={['tags']}
			recoverParamsFilter={['darkMode']}
			recoverParams={true}
		>
			<Icon icon={icon} />
			<div className="visat-IndexNavItem-title">{children}</div>
		</Link>
	);
};

IndexNavItem.propTypes = {
	active: PropTypes.bool,
	disabled: PropTypes.bool,
	icon: PropTypes.string,
	path: PropTypes.string,
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]).isRequired,
};

const IndexNav = ({activeTab}) => {
	return (
		<nav className="visat-IndexNav">
			<IndexNavItem
				icon="ri-datasets"
				active={activeTab === 'datasets'}
				path={'datasets'}
			>
				Datasets
			</IndexNavItem>
			<IndexNavItem
				icon="ri-collections"
				active={activeTab === 'collections'}
				path={'collections'}
			>
				Collections
			</IndexNavItem>
			<IndexNavItem
				icon="ri-applications"
				active={activeTab === 'applications'}
				path={'applications'}
			>
				Applications
			</IndexNavItem>
		</nav>
	);
};

IndexNav.propTypes = {
	activeTab: PropTypes.string,
};

export default IndexNav;
