import PropTypes from 'prop-types';
import {IconTool} from '@gisatcz/visat-components';
import {maxMapsInMapSetCount} from '../../../../constants/app';
import Tooltip from '../../../common/Tooltip';

const AddMapControl = ({addMap, maps, mapMode}) => {
	return (
		<IconTool
			tooltip={{text: 'Add map', position: 'left', component: Tooltip}}
			disabled={maps?.length >= maxMapsInMapSetCount || mapMode === 'compare'}
			onClick={() => addMap()}
			floating
			medium
			icon="ri-add-map"
		/>
	);
};

AddMapControl.propTypes = {
	addMap: PropTypes.func,
	mapMode: PropTypes.string,
	maps: PropTypes.array,
};

export default AddMapControl;
