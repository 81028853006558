import PropTypes from 'prop-types';
import classnames from 'classnames';
import IndexScreen from '../IndexScreen';
import ProductViewerScreen from '../ProductViewerScreen';
import './style.scss';

const getScreen = getScreen => {
	switch (getScreen) {
		case 'productViewerScreen':
			return <ProductViewerScreen />;
		case 'homeScreen':
			return <IndexScreen />;
	}
};

const AppContent = ({isDark, activeScreen}) => {
	const classes = classnames('visat-App', {
		'ptr-light': !isDark,
		'ptr-dark': isDark,
	});

	return <div className={classes}>{getScreen(activeScreen)}</div>;
};

AppContent.propTypes = {
	isDark: PropTypes.bool,
	activeScreen: PropTypes.string,
};

export default AppContent;
