import IndexHeader from './IndexHeader';
import IndexBody from './IndexBody';
import './style.scss';

const AppContent = () => {
	return (
		<div className={'visat-IndexScreen'}>
			<IndexHeader />
			<IndexBody />
		</div>
	);
};

AppContent.propTypes = {};

export default AppContent;
