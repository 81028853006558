import {connect} from '@gisatcz/ptr-state';
import Select from '../../../../../state/Select';
import Action from '../../../../../state/Action';
import routerConstants from '../../../../../state/router/constants';
import Presentation from './presentation';

const mapStateToProps = state => {
	const isDark = Select.router.getDarkModeActive(state);
	return {
		isDark,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onAppModeChange: isDark => {
			// append querystring to the url only if darkmode should be set to true
			// on the other hand, false is default value
			const urlDarkValue = isDark === false ? null : true;
			dispatch(
				Action.router.updateAppUrl(undefined, {
					[routerConstants.darkMode]: urlDarkValue,
				})
			);
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
