import {connect} from '@gisatcz/ptr-state';
import Action from '../../../state/Action';
import Select from '../../../state/Select';
import Presentation from '../ProductTag';

const mapStateToProps = (state, ownProps) => {
	return {
		metadata: Select.visat.tags.getByKey(state, ownProps.tagKey),
	};
};

const mapDispatchToProps = (dispatch, ownProps) => {
	return () => {
		return {
			onMount: () => {
				// console.log('xxx onmount', ownProps.tagKey);
				dispatch(Action.tags.useKeys([ownProps.tagKey]));
			},
			onUnmount: () => {
				// console.log('xxx onunmount');
				dispatch(Action.tags.useKeysClear([ownProps.tagKey]));
			},
		};
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
