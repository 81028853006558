import {connect} from '@gisatcz/ptr-state';
import Select from '../../../../state/Select';

import Presentation from './presentation';

const mapStateToProps = state => {
	return {
		tagsByCategory:
			Select.visat.productFilter.getProductTagsGroupedByCategory(state),
		primaryItems: Select.visat.productFilter.getPrimaryItems(state),
		favouritesTagKey: Select.app.getConfiguration(state, 'tags.isFavourite'),
		recommendedTagKey: Select.app.getConfiguration(state, 'tags.isRecommended'),
	};
};

const mapDispatchToProps = () => {
	return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
