import PropTypes from 'prop-types';
import AppThumbnail from '../../../../common/AppThumbnail';
import AppPreview from './AppPreview';
import {NothingFiltered} from '@gisatcz/visat-components';
import AppPreviewImage from './AppPreview/components/AppPreviewImage';
import AppPreviewBody from './AppPreview/components/AppPreviewBody';
import AppPreviewTitle from './AppPreview/components/AppPreviewTitle';
import AppPreviewDescription from './AppPreview/components/AppPreviewDescription';
import AppPreviewTags from './AppPreview/components/AppPreviewTags';
import AppPreviewTools from './AppPreview/components/AppPreviewTools';
import './style.scss';

const AppList = ({apps}) => {
	let content;
	if (apps?.length) {
		content = (
			<div className="visat-AppList">
				{apps &&
					apps.map((app, index) => {
						const {key, data} = app;
						const {title, description, image, url, tagKeys, internal} = data;

						return (
							<AppPreview key={key} right={index % 2 === 1}>
								<AppPreviewImage>
									<AppThumbnail imageKey={image.key} imageAlt={image.alt} />
								</AppPreviewImage>
								<AppPreviewBody>
									<AppPreviewTitle>{title}</AppPreviewTitle>
									<AppPreviewTags tagKeys={tagKeys} />
									<AppPreviewDescription>{description}</AppPreviewDescription>
									<AppPreviewTools url={url} internal={internal} />
								</AppPreviewBody>
							</AppPreview>
						);
					})}
			</div>
		);
	} else {
		content = (
			<NothingFiltered message="None of the apps match filtering parameters" />
		);
	}

	return content;
};

AppList.propTypes = {apps: PropTypes.array};

export default AppList;
