import {connect} from '@gisatcz/ptr-state';
import Action from '../../../../../state/Action';
import Select from '../../../../../state/Select';

import Presentation from './presentation';
import routerConstants from '../../../../../state/router/constants';

const mapStateToProps = (state, ownProps) => {
	return {
		active: Select.visat.productFilter.isTagPresentInCurrentFilter(
			state,
			ownProps.tagKey
		),
	};
};

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		onClick: () =>
			dispatch(
				Action.router.toggleQueryString(routerConstants.tags, ownProps.tagKey)
			),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
