import {connect} from '@gisatcz/ptr-state';
import {createRoutes} from '../../router';
import Action from '../../state/Action';
import Select from '../../state/Select';
import Presentation from './presentation';

const mapStateToProps = state => {
	const currentRouter = Select.router.getCurrent(state);
	const currentRoute = currentRouter.name;
	const parentPath = Select.router.getRouteParent(currentRoute, createRoutes());
	const viewKey = Select.router.getViewKey(state);
	return {
		parentPath: parentPath?.name,
		viewKey,
	};
};

const mapDispatchToProps = () => {
	return dispatch => {
		return {
			onViewKeyChanged: viewKey => {
				dispatch(Action.views.applyAndSetActive(viewKey, Action));
			},
			onUmnount: () => {
				// remove active view key
				// todo Clear state
				dispatch(Action.visat.clearView());
			},
		};
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
