// import PropTypes from 'prop-types';
import {useState} from 'react';
import {IconTool} from '@gisatcz/visat-components';
import {GeonamesSearch} from '@gisatcz/ptr-components';

import './style.scss';
import PropTypes from 'prop-types';
import Tooltip from '../../../common/Tooltip';

const SearchPlaceControl = ({setMapView}) => {
	const [searchBoxOpen, setSearchBoxOpen] = useState(false);

	return (
		<>
			<IconTool
				active={searchBoxOpen}
				tooltip={{text: 'Search place', position: 'left', component: Tooltip}}
				onClick={() => {
					setSearchBoxOpen(!searchBoxOpen);
				}}
				floating
				medium
				icon="ri-location-search"
			/>
			{searchBoxOpen ? (
				<GeonamesSearch
					autoFocus={true}
					maxPlaces={7}
					onPlaceSelect={setMapView}
					placeholder="Type location name..."
					hideClearIcon={true}
					className="visat-GeonamesSearchBox"
				/>
			) : null}
		</>
	);
};

SearchPlaceControl.propTypes = {
	setMapView: PropTypes.func,
};

export default SearchPlaceControl;
