import {useEffect} from 'react';
import propTypes from 'prop-types';
import AppFilter from './AppFilter';
import AppList from './AppList';
import './style.scss';

const ApplicationCatalog = ({onMount, onUnmount}) => {
	useEffect(() => {
		onMount();
		return onUnmount;
	}, []);

	return (
		<div className="visat-ApplicationCatalog">
			<AppFilter />
			<AppList />
		</div>
	);
};

ApplicationCatalog.propTypes = {
	onMount: propTypes.func,
	onUnmount: propTypes.func,
};

export default ApplicationCatalog;
