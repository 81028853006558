import {IconTool} from '@gisatcz/visat-components';
import AppSettings from './AppSettings';
import './style.scss';

const IndexTools = () => {
	return (
		<div className="visat-IndexTools">
			<IconTool disabled icon="ri-share" />
			<AppSettings />
			<IconTool disabled icon="ri-user" />
		</div>
	);
};

export default IndexTools;
