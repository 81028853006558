export const appKey = 'esaUtepVisat';

export const gaId = window?.runtimeConfig?.googleAnalyticsKey;

export const screens = {
	applications: 'applications',
	collections: 'collections',
	datasets: 'datasets',
};

export const collectionsTagsIndex = {
	filterByActive: {application: true},
	filter: {},
	order: [],
	start: 1,
	length: 1000,
};

export const datasetTagsIndex = {
	filterByActive: {application: true},
	filter: {},
	order: [],
	start: 1,
	length: 1000,
};

// map set
export const mapSetKey = 'map-set-1';
export const maxMapsInMapSetCount = 4;

// timeline
export const timelineLayerLineHeight = 14; //px
export const timelineLayerElementHeight = 8; //px, rest is for line padding

// external default app links
export const defaultLinks = {
	visat: 'https://visat-dev.gisat.cz/',
	unhab: 'https://unhab.gisat.cz/',
	sdg: 'https://visat-sdg-dev.gisat.cz/',
	xcube: 'https://visat-xcube-dev.gisat.cz/',
};
