import {connect} from '@gisatcz/ptr-state';
import Select from '../../../state/Select';

import Presentation from './presentation';

const mapStateToProps = (state, ownProps) => {
	const layers = Select.maps.getLayersStateByMapKey(state, ownProps.mapKey);

	const layersWithStyle = [];

	layers?.forEach(layer => {
		const layerComponent = ownProps.componentsByLayer.find(
			l => l.layerTemplateKey === layer.layerTemplateKey
		);

		if (layer && layer.styleKey) {
			layersWithStyle.push({
				...layer,
				layerComponent,
				style: Select.styles.getByKey(state, layer.styleKey),
			});
		} else if (layer && layerComponent?.legend?.props?.style) {
			layersWithStyle.push({
				...layer,
				layerComponent,
				style: layerComponent?.legend?.props?.style,
			});
		}
	});

	return {
		configuration: [],
		layersWithStyle,
	};
};

const mapDispatchToProps = () => {
	return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
