import {connect} from '@gisatcz/ptr-state';
import {utils} from '@gisatcz/ptr-utils';

import Action from '../../../../../state/Action';
import Select from '../../../../../state/Select';

import Presentation from './presentation';
import routerConstants from '../../../../../state/router/constants';

const mapStateToProps = state => {
	const activeTagKeys = Select.router.getQueryValuesByKey(
		state,
		routerConstants.tags
	);
	return {
		collections: Select.visat.collections.getForCurrentFilter(state),
		activeFilterTagKeys: activeTagKeys,
		viewFilter: Select.visat.collections.getViewFilterByTags(state),
	};
};

const mapDispatchToProps = () => {
	const componentId = `collectionsList_views_${utils.uuid()}`;
	return dispatch => {
		return {
			onViewFilterChanged: viewFilter => {
				dispatch(
					Action.views.useIndexed(
						viewFilter.filterByActive,
						viewFilter.filter,
						viewFilter.order,
						viewFilter.start,
						viewFilter.length,
						componentId
					)
				);
			},
			onUnmount: () => {
				dispatch(Action.views.clearIndex(componentId));
			},
		};
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
