import moment from 'moment';
import {findIndex as _findIndex, isEqual as _isEqual} from 'lodash';
import {Action as CommonAction} from '@gisatcz/ptr-state';
import {Select as CommonSelect} from '@gisatcz/ptr-state';
import {stateManagement} from '@gisatcz/ptr-utils';
import {mapSetKey} from '../../constants/app';
import productViewerActions from './productViewer/actions';

function handleViewInActiveView(viewKey) {
	return (dispatch, getState) => {
		const state = getState();
		const activeView = CommonSelect.views.getActive(state);
		const selectedView = CommonSelect.views.getByKey(state, viewKey);
		if (activeView && selectedView) {
			const isSelectedViewInActiveView =
				!!activeView.data.state.views?.byKey[viewKey];

			if (isSelectedViewInActiveView) {
				dispatch(removeViewFromActiveView(activeView, selectedView));
			} else {
				dispatch(addViewToActiveView(activeView, selectedView));
			}
		}
	};
}

function addViewToActiveView(activeView, selectedView) {
	return (dispatch, getState) => {
		const state = getState();
		const updatedView = {
			...activeView,
			data: {
				...activeView.data,
				state: {
					...activeView.data.state,
					views: activeView.data.state.views
						? {
								byKey: {
									...activeView.data.state.views.byKey,
									[selectedView.key]: selectedView,
								},
						  }
						: {
								byKey: {
									[selectedView.key]: selectedView,
								},
						  },
				},
			},
		};
		dispatch(CommonAction.views.add(updatedView));

		const addedDatasetTimeline = selectedView.data?.state?.components?.Timeline;
		if (addedDatasetTimeline) {
			const {timelineLayers, timelinePeriod} = addedDatasetTimeline;
			const currentTimelineLayers = CommonSelect.components.get(
				state,
				'Timeline',
				'timelineLayers'
			);
			const currentTimelinePeriod = CommonSelect.components.get(
				state,
				'Timeline',
				'timelinePeriod'
			);
			const {start: addedStart, end: addedEnd} = timelinePeriod;
			let {start: currentStart, end: currentEnd} = currentTimelinePeriod;

			if (moment(addedStart).isBefore(currentStart)) {
				currentStart = addedStart;
			}
			if (moment(addedEnd).isAfter(currentEnd)) {
				currentEnd = addedEnd;
			}

			dispatch(
				CommonAction.components.set('Timeline', 'timelineLayers', [
					...currentTimelineLayers,
					...timelineLayers,
				])
			);

			dispatch(
				CommonAction.components.set('Timeline', 'timelinePeriod', {
					start: currentStart,
					end: currentEnd,
				})
			);
		}
	};
}

function removeViewFromActiveView(activeView, selectedView) {
	return (dispatch, getState) => {
		const state = getState();

		const updatedView = {
			...activeView,
			data: {
				...activeView.data,
				state: {
					...activeView.data.state,
					views: {
						...activeView.data.state.views,
						byKey: stateManagement.removeItemByKey(
							activeView.data.state.views.byKey,
							selectedView.key
						),
					},
				},
			},
		};
		dispatch(CommonAction.views.add(updatedView));

		const removedDatasetTimeline =
			selectedView.data?.state?.components?.Timeline;
		if (removedDatasetTimeline) {
			const {timelineLayers} = removedDatasetTimeline;
			const currentTimelineLayers = CommonSelect.components.get(
				state,
				'Timeline',
				'timelineLayers'
			);

			const indexOfRemoved = _findIndex(currentTimelineLayers, layer =>
				_isEqual(layer, timelineLayers[0])
			);

			if (indexOfRemoved > -1) {
				dispatch(
					CommonAction.components.set(
						'Timeline',
						'timelineLayers',
						stateManagement.removeItemByIndex(
							currentTimelineLayers,
							indexOfRemoved
						)
					)
				);
			}
		}
	};
}

function clearView() {
	return (dispatch, getState) => {
		const activeView = CommonSelect.views.getActive(getState());
		const updatedView = {
			...activeView,
			data: {...activeView.data, state: {...activeView.data.state, views: {}}},
		};

		dispatch(CommonAction.views.add(updatedView));
		//
		// Clear components.Timeline state
		//
		dispatch(CommonAction.components.remove('Timeline'));

		//
		// Clear map state
		//
		const mapsInUse = CommonSelect.maps.getAllMapSetsMaps(getState()) || [];
		mapsInUse.forEach(mapKey => {
			dispatch(CommonAction.maps.removeMap(mapKey));
		});
		dispatch(CommonAction.maps.removeMapSet(mapSetKey));

		dispatch(CommonAction.components.remove('Map'));
	};
}

export default {
	handleViewInActiveView,
	clearView,
	productViewer: productViewerActions,
};
