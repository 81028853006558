import {useEffect} from 'react';
import propTypes from 'prop-types';
import './style.scss';
import CatalogFilter from '../CatalogFilter';
import CollectionList from './CollectionList';

const CollectionCatalog = ({onMount, onUnmount}) => {
	useEffect(() => {
		onMount();
		return onUnmount;
	}, []);

	return (
		<div className="visat-CollectionCatalog">
			<CatalogFilter />
			<CollectionList />
		</div>
	);
};

CollectionCatalog.propTypes = {
	onMount: propTypes.func,
	onUnmount: propTypes.func,
};

export default CollectionCatalog;
