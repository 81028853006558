import {connect} from '@gisatcz/ptr-state';
import Presentation from './presentation';
import Select from '../../../../../state/Select';

const mapStateToProps = (state, ownProps) => {
	return {
		layerTemplate: Select.layerTemplates.getByKey(
			state,
			ownProps.layerTemplateKey
		),
	};
};

const mapDispatchToProps = () => {
	return {
		onMount: () => {
			//load on mount?
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
