import classnames from 'classnames';
import PropTypes from 'prop-types';

const ProductCardTools = ({className, left, right}) => {
	const classes = classnames('ptr-ProductCardTools', {}, className);

	return (
		<div className={classes}>
			<div className="ptr-ProductCardTools-left">{left}</div>
			<div className="ptr-ProductCardTools-right">{right}</div>
		</div>
	);
};

ProductCardTools.propTypes = {
	className: PropTypes.string,
	left: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),
	right: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),
};

export default ProductCardTools;
