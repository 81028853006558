import PropTypes from 'prop-types';
import Modal from 'react-modal';
import {IconTool, Tag, TagsContainer} from '@gisatcz/visat-components';
import ModalWindow from '../../../../../common/ModalWindow';
import ProductCard from '../../../../../common/ProductCard';
import ProductCardHeader from '../../../../../common/ProductCard/components/ProductCardHeader';
import ProductCardBody from '../../../../../common/ProductCard/components/ProductCardBody';
import ProductCardTitle from '../../../../../common/ProductCard/components/ProductCardTitle';
import ProductCardDescription from '../../../../../common/ProductCard/components/ProductCardDescription';
import ProductCardFooter from '../../../../../common/ProductCard/components/ProductCardFooter';
import ProductCardTools from '../../../../../common/ProductCard/components/ProductCardTools';
import ProductDetails from '../../../../../common/ProductDetails';
import Link from '../../../../../common/Link';
import DatasetTag from './DatasetCardTag';
import AddDatasetButton from './AddDatasetButton';
import {getRouter} from '../../../../../../router';

import './style.scss';
import Tooltip from '../../../../../common/Tooltip';

Modal.setAppElement('#root');

const datasetMapRouteName = 'datasetsMap';

const DatasetCard = ({
	addDatasetMode,
	name,
	description,
	tagKeys,
	viewKey,
	placeholder,
	openDetails,
	closeDetails,
	detailsOpen,
}) => {
	const router = getRouter();
	if (!placeholder) {
		return (
			<>
				<ProductCard className="visat-DatasetCard">
					<ProductCardHeader>
						<TagsContainer small>
							{tagKeys.map(tagKey => (
								<DatasetTag key={tagKey} tagKey={tagKey} />
							))}
						</TagsContainer>
					</ProductCardHeader>
					<ProductCardBody>
						<ProductCardTitle>
							{addDatasetMode ? (
								name
							) : (
								<Link
									name={datasetMapRouteName}
									router={router}
									paramsFilter={['tags']}
									updateParams={{viewKey}}
								>
									{name}
								</Link>
							)}
						</ProductCardTitle>
						<ProductCardDescription>{description}</ProductCardDescription>
					</ProductCardBody>
					<ProductCardFooter>
						<ProductCardTools
							left={
								<IconTool
									onClick={() => openDetails(viewKey)}
									icon="ri-more-dots"
									tooltip={{
										text: 'Details',
										id: 'datasetCatalog_details',
										component: Tooltip,
									}}
								/>
							}
							right={
								<>
									{addDatasetMode ? (
										<AddDatasetButton viewKey={viewKey} />
									) : null}
									<IconTool disabled icon="ri-star" />
								</>
							}
						/>
					</ProductCardFooter>
				</ProductCard>
				<ModalWindow isOpen={detailsOpen} onClose={closeDetails} className="">
					<ProductDetails
						addDatasetMode={addDatasetMode}
						viewKey={viewKey}
						onClose={closeDetails}
						mapRouteName={datasetMapRouteName}
					/>
				</ModalWindow>
			</>
		);
	} else {
		return (
			<ProductCard placeholder>
				<ProductCardHeader>
					<TagsContainer>
						<Tag placeholder="light" />
						<Tag placeholder="light" />
						<Tag placeholder="light" />
						<Tag placeholder="light" />
						<Tag placeholder="light" />
					</TagsContainer>
				</ProductCardHeader>
				<ProductCardBody>
					<ProductCardTitle placeholder />
					<ProductCardDescription placeholder />
					<ProductCardDescription placeholder />
				</ProductCardBody>
			</ProductCard>
		);
	}
};

DatasetCard.propTypes = {
	addDatasetMode: PropTypes.bool,
	description: PropTypes.string,
	name: PropTypes.string,
	placeholder: PropTypes.bool,
	tagKeys: PropTypes.array,
	viewKey: PropTypes.string,
	openDetails: PropTypes.func,
	closeDetails: PropTypes.func,
	detailsOpen: PropTypes.bool,
};

export default DatasetCard;
