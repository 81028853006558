import {createSelector} from 'reselect';
import {
	filter as _filter,
	flatten as _flatten,
	difference as _difference,
	includes as _includes,
	isEmpty as _isEmpty,
	uniq as _uniq,
} from 'lodash';
import {commonSelectors, Select as CommonSelect} from '@gisatcz/ptr-state';
import RouterSelectors from '../../router/selectors';
import productFilterSelectors from '../../visat/productFilter/selectors';

const getSubstate = state => state.specific.visatApplications;

const getAll = commonSelectors.getAll(getSubstate);

const getForCurrentFilter = createSelector(
	[RouterSelectors.getTags, getAll],
	(activeFilterTagKeys, applications) => {
		if (!activeFilterTagKeys?.length) {
			return applications;
		} else {
			return _filter(
				applications,
				application =>
					_difference(activeFilterTagKeys, application.data?.tagKeys)
						?.length === 0
			);
		}
	}
);

const getAvailableTags = createSelector(
	[CommonSelect.tags.getAllAsObject, getAll],
	(tags, applications) => {
		if (tags && applications) {
			const tagKeys = _uniq(
				_flatten(applications.map(app => app.data.tagKeys))
			);
			if (tagKeys?.length) {
				return tagKeys.map(tagKey => tags[tagKey]);
			} else {
				return null;
			}
		} else {
			return null;
		}
	}
);

/**
 * Get product tags grouped by category
 * @param state {Object}
 * @return {Array | null}
 */
const getProductTagsGroupedByCategory = createSelector(
	[productFilterSelectors.getCategories, getAvailableTags],
	(categoryTags, productTags) => {
		if (categoryTags && productTags) {
			let tagsGroupedByCategory = {};

			categoryTags.forEach(categoryTag => {
				const {key, data} = categoryTag;
				if (!tagsGroupedByCategory[key]) {
					tagsGroupedByCategory[key] = {
						key,
						data,
						tags: [],
					};
				}

				productTags.forEach(productTag => {
					if (_includes(productTag.data.tagKeys, categoryTag.key)) {
						tagsGroupedByCategory[key].tags.push(productTag);
					}
				});

				if (!tagsGroupedByCategory[key].tags.length) {
					delete tagsGroupedByCategory[key];
				}
			});

			if (_isEmpty(tagsGroupedByCategory)) {
				return null;
			} else {
				return Object.values(tagsGroupedByCategory);
			}
		} else {
			return null;
		}
	}
);

export default {
	getForCurrentFilter,
	getProductTagsGroupedByCategory,
};
