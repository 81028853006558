import {connect} from '@gisatcz/ptr-state';
import Presentation from './presentation';
import Select from '../../../../state/Select';
import Action from '../../../../state/Action';
const componentId = 'StyleBasedLegend';

const mapStateToProps = (state, ownProps) => {
	const style = ownProps.style;
	return {
		styleKey: ownProps.styleKey,
		baseStyle: style?.data?.definition?.rules?.[0]?.styles[0],
		attributeStyle: style?.data?.definition?.rules?.[0]?.styles[1],
		attributeMetadata: style?.data?.definition?.rules?.[0]?.styles[1]
			?.attributeKey
			? Select.attributes.getByKey(
					state,
					style?.data?.definition?.rules?.[0]?.styles[1]?.attributeKey
			  )
			: null,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onAttributeChange: attributeKey => {
			dispatch(Action.attributes.useKeys([attributeKey], componentId));
		},
		onStyleChange: styleKey => {
			dispatch(Action.styles.useKeys([styleKey], componentId));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
