import {useEffect} from 'react';
import propTypes from 'prop-types';

import CatalogFilter from '../CatalogFilter';
import DatasetList from './DatasetList';
import './style.scss';

const DatasetCatalog = ({onMount, onUnmount, addDatasetMode}) => {
	useEffect(() => {
		onMount();
		return onUnmount;
	}, []);

	return (
		<div className="visat-DatasetCatalog">
			<CatalogFilter />
			<DatasetList addDatasetMode={addDatasetMode} />
		</div>
	);
};

DatasetCatalog.propTypes = {
	addDatasetMode: propTypes.bool,
	onMount: propTypes.func,
	onUnmount: propTypes.func,
};

export default DatasetCatalog;
