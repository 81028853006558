import PropTypes from 'prop-types';
import globalWsf from '../../assets/img/apps/global-wsf.webp';
import greenAreas from '../../assets/img/apps/green-areas.webp';
import landAssets from '../../assets/img/apps/land-assets.webp';
import nordmap from '../../assets/img/apps/nordmap.webp';
import sdg from '../../assets/img/apps/sdg.webp';
import insarSamoa from '../../assets/img/apps/insar-samoa.webp';
import citydatacube from '../../assets/img/apps/citydatacube.webp';
import unhab from '../../assets/img/apps/unhab.webp';

const AppThumbnail = ({imageKey, imageAlt}) => {
	switch (imageKey) {
		case 'scudeoStories-globalWsf':
			return <img src={globalWsf} alt={imageAlt} />;
		case 'scudeoStories-greenAreas':
			return <img src={greenAreas} alt={imageAlt} />;
		case 'scudeoStories-landAssets':
			return <img src={landAssets} alt={imageAlt} />;
		case 'citydatacube':
			return <img src={citydatacube} alt={imageAlt} />;
		case 'nordmap':
			return <img src={nordmap} alt={imageAlt} />;
		case 'sdg':
			return <img src={sdg} alt={imageAlt} />;
		case 'insar-samoa':
			return <img src={insarSamoa} alt={imageAlt} />;
		case 'unhab':
			return <img src={unhab} alt={imageAlt} />;
		default:
			return null;
	}
};

AppThumbnail.propTypes = {
	imageAlt: PropTypes.string,
	imageKey: PropTypes.string,
};

export default AppThumbnail;
