import classnames from 'classnames';
import PropTypes from 'prop-types';
import {Icon, Toggle} from '@gisatcz/visat-components';
import './style.scss';

const CatalogFilterPrimaryItem = ({
	active,
	className,
	disabled,
	icon,
	name,
	onClick,
	placeholder,
}) => {
	const classes = classnames(
		'visat-CatalogFilterPrimaryItem',
		{
			'is-active': active,
			'is-interactive': !!onClick,
			'is-disabled': disabled,
			'is-placeholder': placeholder,
		},
		className
	);

	const handleKeyDown = event => {
		if (event.key === 'Enter') {
			onClick();
		}
	};

	if (placeholder) {
		return <div className={classes} />;
	} else {
		return (
			<div
				className={classes}
				tabIndex={disabled ? -1 : 0}
				onClick={onClick}
				onKeyDown={handleKeyDown}
			>
				<div className="visat-CatalogFilterPrimaryItem-title">
					{icon ? <Icon icon={icon} /> : null}
					<div className="visat-CatalogFilterPrimaryItem-name">{name}</div>
				</div>
				<div className="visat-CatalogFilterPrimaryItem-toggle">
					<Toggle disabled={disabled} on={active} />
				</div>
			</div>
		);
	}
};

CatalogFilterPrimaryItem.propTypes = {
	active: PropTypes.bool,
	className: PropTypes.string,
	disabled: PropTypes.bool,
	icon: PropTypes.string,
	name: PropTypes.string,
	onClick: PropTypes.func,
	placeholder: PropTypes.bool,
	tagKey: PropTypes.string,
};

export default CatalogFilterPrimaryItem;
