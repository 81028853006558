import PropTypes from 'prop-types';
import {Button} from '@gisatcz/ptr-atoms';
import {Icon} from '@gisatcz/visat-components';
import './style.scss';

const AddDatasetButton = ({active, self, handleDataset}) => {
	return (
		<Button
			className="visat-AddDatasetButton"
			small
			ghost
			disabled={self}
			primary={active}
			onClick={handleDataset}
		>
			{active ? <Icon icon="ri-check" /> : <Icon icon="plus" />}
			{active ? 'Added' : 'Add'}
		</Button>
	);
};

AddDatasetButton.propTypes = {
	active: PropTypes.bool,
	self: PropTypes.bool,
	viewKey: PropTypes.string,
	handleDataset: PropTypes.func,
};

export default AddDatasetButton;
