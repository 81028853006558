import classnames from 'classnames';
import PropTypes from 'prop-types';

const ProductCardTitle = ({className, placeholder, children}) => {
	const classes = classnames(
		'ptr-ProductCardTitle',
		{
			'is-placeholder': placeholder,
		},
		className
	);

	return <h3 className={classes}>{children}</h3>;
};

ProductCardTitle.propTypes = {
	className: PropTypes.string,
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),
	placeholder: PropTypes.bool,
	viewKey: PropTypes.string,
};

export default ProductCardTitle;
