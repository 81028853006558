import {createElement} from 'react';
import PropTypes from 'prop-types';
import './style.scss';

const MapLegends = ({
	componentsByLayer,
	mapKey,
	className,
	layersWithStyle,
}) => {
	if (componentsByLayer) {
		return (
			<div className={className}>
				{layersWithStyle?.map(legendConfiguration => {
					if (legendConfiguration?.layerComponent?.legend?.component) {
						return createElement(
							legendConfiguration?.layerComponent?.legend?.component,
							{
								...legendConfiguration?.layerComponent?.legend.props,
								style: legendConfiguration?.style,
								mapKey,
								layerTemplateKeys: [legendConfiguration.layerTemplateKey],
							}
						);
					} else {
						return null;
					}
				})}
			</div>
		);
	} else {
		return null;
	}
};

MapLegends.propTypes = {
	className: PropTypes.string,
	componentsByLayer: PropTypes.array,
	mapKey: PropTypes.string,
	configuration: PropTypes.array,
	layersWithStyle: PropTypes.array,
};

export default MapLegends;
