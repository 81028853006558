import {create as createRouter} from '@gisatcz/ptr-router';

// base styles need to be imported before all components
import '@gisatcz/ptr-core/lib/styles/reset.css';
import '@gisatcz/ptr-core/lib/styles/base.scss';
import Actions from './state/Action';
import {getStoreFromRequest} from './state/router/utils';
import './styles/index.scss';

let router;

function createRoutes() {
	return {
		'': {
			name: 'home',
			handler: request => {
				const store = getStoreFromRequest(request);
				const dispatch = store.dispatch;
				dispatch(Actions.router.updateAppUrl('datasets'));
				// todo set some default page??
				// request.context.next();
				// console.log('xxx home');
			},
		},
		'/applications': {
			name: 'applications',
			handler: () => {
				// request.context.next();
			},
		},
		'/collections': {
			name: 'collections',
			handler: request => {
				request.context.next();
			},
			children: {
				'/map': {
					name: 'collectionsMap',
					handler: () => {
						// console.log('xxx collection map');
						// request.context.next();
					},
				},
			},
		},
		'/datasets': {
			name: 'datasets',
			handler: request => {
				request.context.next();
			},
			children: {
				'/map': {
					name: 'datasetsMap',
					handler: () => {
						// console.log('xxx datasets map');
					},
				},
			},
		},
	};
}

/**
 * Creates router instance that can be used to manipulate urls.
 *
 * App handler updates store with current page and it's up to views to react to the change.
 * In case of url change, redux selector possibly retrieves different data and passes them
 * into some the component.
 *
 */
const initRouter = (absPath, currentUrl, Store, isPreloaded, navHandler) => {
	router = createRouter({
		rootUrl: absPath,
		currentUrl,
		routes: createRoutes(),
		navHandler,
		store: Store,
		generateUrlsOptions: {
			stringifyQueryParams: params => new URLSearchParams(params).toString(),
		},
	});

	return router;
};

const getRouter = () => router;

export {getRouter, initRouter, createRoutes};
