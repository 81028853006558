import {createSelector} from 'reselect';
import {
	difference as _difference,
	filter as _filter,
	includes as _includes,
} from 'lodash';
import {Select as CommonSelect} from '@gisatcz/ptr-state';

import RouterSelectors from '../../router/selectors';

/**
 * Return all datasets
 * @param state {Object}
 * @return {Array | null}
 */
const getAll = createSelector(
	[
		state => CommonSelect.app.getConfiguration(state, 'tags.isCollection'),
		CommonSelect.views.getAll,
	],
	(isCollectionTagKey, views) => {
		if (views?.length && isCollectionTagKey) {
			return _filter(views, view =>
				_includes(view.data?.tagKeys, isCollectionTagKey)
			);
		} else {
			return null;
		}
	}
);

/**
 * Get datasets which fit current dataset filter
 * @param state {Object}
 * @return {Array | null}
 **/
const getForCurrentFilter = createSelector(
	[RouterSelectors.getTags, getAll],
	(activeFilterTagKeys, collections) => {
		if (!activeFilterTagKeys?.length) {
			return collections;
		} else {
			return _filter(
				collections,
				collection =>
					_difference(activeFilterTagKeys, collection.data?.tagKeys)?.length ===
					0
			);
		}
	}
);

/**
 * Get collections filter
 * @param state {Object}
 * @return {Array | null}
 **/
const getViewFilterByTags = createSelector(
	[RouterSelectors.getTags],
	activeFilterTagKeys => {
		return {
			filterByActive: {application: true},
			filter: {
				// TODO
				...(activeFilterTagKeys?.length
					? {
							tagKeys: {
								in: [
									...(activeFilterTagKeys?.length ? activeFilterTagKeys : []),
								],
							},
					  }
					: {}),
			},
			order: [],
			start: 1,
			length: 1000,
		};
	}
);

export default {
	getAll,
	getForCurrentFilter,
	getViewFilterByTags,
};
