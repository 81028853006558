import {connect} from '@gisatcz/ptr-state';
import Presentation from './presentation';
import Action from '../../../../../state/Action';
import Select from '../../../../../state/Select';
import routerConstants from '../../../../../state/router/constants';

const mapStateToProps = state => {
	const activeTagKeys = Select.router.getQueryValuesByKey(
		state,
		routerConstants.tags
	);
	return {
		activeTagKeys,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onTagClick: tagKey =>
			dispatch(Action.router.toggleQueryString(routerConstants.tags, tagKey)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
