import {connect} from '@gisatcz/ptr-state';
import Action from '../../../../state/Action';
import Select from '../../../../state/Select';
import {mapSetKey} from '../../../../constants/app';
import Presentation from './presentation';

const mapStateToProps = (state, ownProps) => {
	return {
		activeMapKey: Select.maps.getMapSetActiveMapKey(state, mapSetKey),
		layersState: Select.maps.getLayersStateByMapKey(state, ownProps.mapKey),
		mapSetMapKeys: Select.maps.getMapSetMapKeys(state, mapSetKey),
		layerTemplateKeysForLegend:
			Select.visat.getLayerTemplateConfigurations(state),
	};
};

const mapDispatchToProps = dispatch => {
	return {
		removeMap: mapKey => {
			dispatch(Action.maps.removeMap(mapKey));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
