import {connect} from '@gisatcz/ptr-state';
import Presentation from './presentation';
import Action from '../../../../../../../state/Action';
import Select from '../../../../../../../state/Select';

const mapStateToProps = (state, ownProps) => {
	return {
		active: Select.visat.isViewPartOfActiveView(state, ownProps.viewKey),
		self: Select.visat.datasets.isActive(state, ownProps.viewKey),
	};
};

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		handleDataset: () => {
			dispatch(Action.visat.handleViewInActiveView(ownProps.viewKey));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
