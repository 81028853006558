import PropTypes from 'prop-types';
import {useEffect} from 'react';

const Layer = ({title, onMount, onUnmount, lineHeight, className}) => {
	useEffect(() => {
		onMount();

		return onUnmount;
	}, []);

	return (
		<span
			className={className}
			style={{lineHeight: `${lineHeight}px`}}
			title={`${title}`}
		>
			{title}
		</span>
	);
};

Layer.propTypes = {
	onMount: PropTypes.func,
	onUnmount: PropTypes.func,
	title: PropTypes.string,
	className: PropTypes.string,
	lineHeight: PropTypes.number,
};

export default Layer;
