import {connect} from '@gisatcz/ptr-state';
import Select from '../../../../../../state/Select';
import Presentation from '../../../../../common/ProductTag';

const mapStateToProps = (state, ownProps) => {
	return {
		active: Select.visat.productFilter.isTagPresentInCurrentFilter(
			state,
			ownProps.tagKey
		),
		metadata: Select.visat.tags.getByKeyForDatasetCard(state, ownProps.tagKey),
	};
};

const mapDispatchToProps = () => {
	return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
