import {createSelector} from 'reselect';
import {Select as CommonSelect} from '@gisatcz/ptr-state';
import RouterSelectors from '../../router/selectors';

import {
	timelineLayerElementHeight,
	timelineLayerLineHeight,
} from '../../../constants/app';

const timelineComponentKey = 'Timeline';
const timelineLayersKey = 'timelineLayers';
const timelinePeriodKey = 'timelinePeriod';

const defaultTimelineRow = {
	lineHeight: timelineLayerLineHeight,
	elementHeight: timelineLayerElementHeight,
	legend: {
		// layerTemplateKey,
	},
	items: [],
	controlMapState: true,
	allowNonActiveLayer: true,
};

const defaultTimelineRowItemColors = {
	basic: '#98dbcc',
	active: 'var(--accent50)',
};

const darkTimelineRowItemColors = {
	basic: '#255951',
	active: 'var(--accent50)',
};

const defaultTimelineRowItem = {
	periods: {
		// layerTemplateKey
		filterByActive: {
			application: true,
		},
	},
	// mapZIndex: 2,
	states: ['basic', 'active', 'hover', 'disabled'],
	activeStates: ['basic'],
	layerState: {
		// layerTemplateKey,
		filterByActive: {
			application: true,
		},
	},
};

const defaultTimelinePeriod = {
	start: '2019-01-02',
	end: '2021-01-01',
};

const getTimelineItem = (timelineComponentItem, darkMode) => {
	const merged = {
		...defaultTimelineRowItem,
		...timelineComponentItem,
		colors: darkMode ? darkTimelineRowItemColors : defaultTimelineRowItemColors,
	};
	return merged;
};

const getTimelineRow = (timelineComponentsLayer, darkMode) => {
	const merged = {
		...defaultTimelineRow,
		...timelineComponentsLayer,
	};

	merged.items = merged.items.map(item => getTimelineItem(item, darkMode));

	return merged;
};

/**
 * Get timeline layers configurations
 * @return {Array}
 */
const getTimelineLayers = createSelector(
	[CommonSelect.components.getSubstate, RouterSelectors.getDarkModeActive],
	(components, darkMode) => {
		const timelineComponentsLayers =
			components?.[timelineComponentKey]?.[timelineLayersKey] || [];

		return timelineComponentsLayers.map(layer =>
			getTimelineRow(layer, darkMode)
		);
	}
);

/**
 * Get timeline period extent
 * @return {Object}
 */
const getTimelinePeriod = createSelector(
	[CommonSelect.components.getSubstate],
	components => {
		return (
			components?.[timelineComponentKey]?.[timelinePeriodKey] ||
			defaultTimelinePeriod
		);
	}
);

export default {
	getTimelineLayers,
	getTimelinePeriod,
};
