import {useEffect} from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {Icon, NothingFiltered} from '@gisatcz/visat-components';
import CollectionCard from './CollectionCard';

import './style.scss';

const CollectionList = ({
	activeFilterTagKeys,
	collections,
	viewFilter,
	onViewFilterChanged,
	onUnmount,
}) => {
	useEffect(() => {
		return onUnmount;
	}, []);

	useEffect(() => {
		onViewFilterChanged(viewFilter);
	}, [viewFilter]);

	let countContent = `Loading collections...`;
	let collectionsContent, nothingFiltered;

	if (activeFilterTagKeys?.length || collections) {
		countContent = `Showing ${collections?.length} ${
			collections?.length === 1 ? 'collection' : 'collections'
		}`;

		if (collections?.length) {
			collectionsContent =
				collections &&
				collections.map(collection => {
					return (
						<CollectionCard
							key={collection.key}
							viewKey={collection.key}
							tagKeys={collection.data?.tagKeys}
							name={collection.data?.nameDisplay}
							description={collection.data?.description}
						/>
					);
				});
		} else {
			nothingFiltered = true;
			collectionsContent = (
				<NothingFiltered message="None of the datasets match filtering parameters" />
			);
		}
	} else {
		collectionsContent = (
			<>
				<CollectionCard placeholder />
				<CollectionCard placeholder />
				<CollectionCard placeholder />
				<CollectionCard placeholder />
				<CollectionCard placeholder />
				<CollectionCard placeholder />
				<CollectionCard placeholder />
				<CollectionCard placeholder />
				<CollectionCard placeholder />
			</>
		);
	}

	const bodyClasses = classnames('visat-CollectionList-body', {
		'nothing-filtered': nothingFiltered,
	});

	return (
		<div className="visat-CollectionList">
			<div className="visat-CollectionList-header">
				<div className="visat-CollectionList-count">{countContent}</div>
				<div className="visat-CollectionList-orders">
					<div>From A to Z</div>
					<Icon icon="ri-chevron-down" />
				</div>
			</div>
			<div className={bodyClasses}>{collectionsContent}</div>
		</div>
	);
};

CollectionList.propTypes = {
	activeFilterTagKeys: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
	collections: PropTypes.array,
	viewFilter: PropTypes.object,
	onViewFilterChanged: PropTypes.func,
	onUnmount: PropTypes.func,
};

export default CollectionList;
