import {DEFAULT_INITIAL_STATE, commonReducers} from '@gisatcz/ptr-state';
import ActionTypes from '../../../constants/ActionTypes';

const INITIAL_STATE = {...DEFAULT_INITIAL_STATE};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case ActionTypes.SPECIFIC.VISAT_APPLICATIONS.ADD:
			return commonReducers.add(state, action);
		default:
			return state;
	}
};
