import {Children, cloneElement} from 'react';
import {ReactCompareSlider} from 'react-compare-slider';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {connects} from '@gisatcz/ptr-state';
import {
	MapScale,
	MapSet,
	PresentationMap,
	ReactLeafletMap,
	MapControls,
} from '@gisatcz/ptr-maps';
import AddMapControl from './AddMapControl';
import MapAttribution from './MapAttribution';
import CompareMapsControl from './CompareMapsControl';
import MapWrapper from './MapWrapper';
import SearchPlaceControl from './SearchPlaceControl';
import {mapSetKey} from '../../../constants/app';
import './style.scss';

const ConnectedMap = connects.Map(PresentationMap);
const ConnectedMapSet = connects.MapSet(MapSet);

const MapComponentsPositionWrapper = props => {
	const {children, className, ...restProps} = props;
	const classes = classnames('', {}, className);

	return (
		<div className={classes}>
			{Children.map(children, child => {
				return cloneElement(child, {...restProps});
			})}
		</div>
	);
};

MapComponentsPositionWrapper.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node,
};

const Map = ({maps, mode}) => {
	return (
		<div className="visat-Map">
			{mode === 'compare' ? (
				<ReactCompareSlider
					onlyHandleDraggable
					className="visat-MapCompareSlider"
					itemOne={
						<ConnectedMap
							mapComponent={ReactLeafletMap}
							stateMapKey={maps[0].key}
							wrapper={MapWrapper}
							wrapperProps={{
								toolsLeft: true,
								noRemove: true,
								labelsOffset: true,
							}}
						>
							<MapComponentsPositionWrapper className="visat-MapSetInfo">
								<MapScale maxWidth={100} />
								<MapAttribution />
							</MapComponentsPositionWrapper>
						</ConnectedMap>
					}
					itemTwo={
						<ConnectedMap
							mapComponent={ReactLeafletMap}
							stateMapKey={maps[1].key}
							wrapper={MapWrapper}
							wrapperProps={{
								noRemove: true,
								labelsRight: true,
								labelsOffset: true,
							}}
						>
							<MapComponentsPositionWrapper className="visat-MapSetInfo">
								<MapScale maxWidth={100} />
								<MapAttribution />
							</MapComponentsPositionWrapper>
							<MapComponentsPositionWrapper className="visat-MapSetControls">
								<SearchPlaceControl />
								<CompareMapsControl />
								<AddMapControl />
								<MapControls levelsBased zoomOnly />
							</MapComponentsPositionWrapper>
						</ConnectedMap>
					}
				/>
			) : (
				<ConnectedMapSet
					stateMapSetKey={mapSetKey}
					mapComponent={ReactLeafletMap}
					connectedMapComponent={ConnectedMap}
					wrapper={MapWrapper}
				>
					<MapComponentsPositionWrapper
						className="visat-MapSetInfo"
						key={'map-scale-attribution'}
					>
						<MapScale maxWidth={100} />
						<MapAttribution />
					</MapComponentsPositionWrapper>
					<MapComponentsPositionWrapper
						className="visat-MapSetControls"
						key={'map-controls'}
					>
						<SearchPlaceControl />
						<CompareMapsControl />
						<AddMapControl />
						<MapControls levelsBased zoomOnly />
					</MapComponentsPositionWrapper>
				</ConnectedMapSet>
			)}
		</div>
	);
};

Map.propTypes = {
	maps: PropTypes.array,
	mode: PropTypes.string,
};

export default Map;
