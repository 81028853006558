import PropTypes from 'prop-types';
import {Button} from '@gisatcz/ptr-atoms';
import {Icon, TagsContainer} from '@gisatcz/visat-components';
import ProductDetailTag from './ProductDetailTag';
import ProductCardTitle from '../ProductCard/components/ProductCardTitle';
import ProductCardDescription from '../ProductCard/components/ProductCardDescription';
import Link from '../../common/Link';
import {getRouter} from '../../../router';

import './style.scss';

const ProductDetails = ({
	addDatasetMode,
	view,
	onClose,
	mapRouteName,
	viewKey,
}) => {
	const {nameDisplay, description, state, tagKeys} = view?.data || {};
	const longDescription = state?.components?.View?.longDescription;
	const router = getRouter();
	return (
		<div className="visat-ProductDetails">
			<div className="visat-ProductDetails-header">
				<TagsContainer small>
					{tagKeys?.map(tagKey => (
						<ProductDetailTag key={tagKey} tagKey={tagKey} />
					))}
				</TagsContainer>
			</div>
			<div className="visat-ProductDetails-body">
				<ProductCardTitle>{nameDisplay}</ProductCardTitle>
				<ProductCardDescription large>
					{longDescription || description}
				</ProductCardDescription>
			</div>
			{mapRouteName ? (
				<div className="visat-ProductDetails-footer">
					<Button invisible onClick={onClose}>
						<Icon icon="ri-back" />
						Back to the list
					</Button>
					{addDatasetMode ? null : (
						<Link
							name={mapRouteName}
							router={router}
							recoverParams={true}
							paramsFilter={['tags', 'detailsViewKey']}
							updateParams={{viewKey}}
						>
							<Button primary onClick={() => {}}>
								Explore dataset <Icon icon="ri-forward" />
							</Button>
						</Link>
					)}
				</div>
			) : null}
		</div>
	);
};

ProductDetails.propTypes = {
	addDatasetMode: PropTypes.bool,
	onClose: PropTypes.func,
	viewKey: PropTypes.string,
	mapRouteName: PropTypes.string,
	view: PropTypes.object,
};

export default ProductDetails;
