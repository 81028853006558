export default {
	// VIIRS Night Lights Collection
	// START
	'00e91683-38ee-47bd-a96c-4fcb0f570757': {
		unit: '%',
		expandable: true,
	},
	'1b001bd0-d447-47ba-a47c-dd3db548bca6': {
		unit: '%',
		expandable: true,
	},
	'79bf65cf-5399-48b3-9835-1fe78fb2cff9': {
		unit: '%',
		expandable: true,
	},
	'11dfb9ac-8203-4be9-8888-2de9539a9b9b': {
		unit: '%',
		expandable: true,
	},
	'4badf1cb-02a1-406e-8f3b-b18f0711a06c': {
		unit: '%',
		expandable: true,
	},
	'7e3f4508-a010-4123-b0b3-01fa1d4ea238': {
		unit: '%',
		expandable: true,
	},
	'3c4f002e-a21b-46f0-bf1b-f3f913933b54': {
		unit: '%',
		expandable: true,
	},
	'cb4fef40-12e3-498d-b013-20c81da0f206': {
		unit: '%',
		expandable: true,
	},
	'851c4668-1265-4d85-a677-4c10f3c321af': {
		unit: '%',
		expandable: true,
	},
	'a52c10b1-5274-4d49-8f41-2dde91e6f562': {
		unit: '%',
		expandable: true,
	},
	'e6595c80-9aef-4a83-8ed4-6cae0b53bd2f': {
		unit: '%',
		expandable: true,
	},
	'c5e4d1ce-ce61-4fd0-8e88-1b19419344d9': {
		unit: '%',
		expandable: true,
	},
	'0a330c90-8523-42a0-aa67-e73c6ac01435': {
		unit: '%',
		expandable: true,
	},
	'7c8240fa-a7cc-4a61-b43b-1948030f34c6': {
		unit: '%',
		expandable: true,
	},
	'33db80b0-823d-41db-8507-aa9d87ede06a': {
		unit: '%',
		expandable: true,
	},
	'14cba198-a5df-4660-955a-13e6f3b26b1c': {
		unit: '%',
		expandable: true,
	},
	'8bb79f0a-e048-448d-997b-177d97d550f1': {
		unit: '%',
		expandable: true,
	},
	'5117f810-9e7f-4b9f-82dc-683bb8e656a1': {
		unit: '%',
		expandable: true,
	},
	'c1adce54-d978-46de-9e28-1a80ed94951e': {
		unit: '%',
		expandable: true,
	},

	// VIIRS Night Lights Collection
	// END

	// WorldPop, GPW
	// START

	'309a93e6-695f-424c-b4e1-1c8ac42b2452': {
		// unit: 'FIXME population?',
		unit: 'Population per square km',
		expandable: true,
	},

	'896dd92a-78bd-480a-96c0-8e37089a04fb': {
		unit: '',
		expandable: true,
	},
	'0c8136c2-3b7e-46dc-9911-6fc36746e691': {
		unit: '',
		expandable: true,
	},
	'f5b10e3c-b113-4109-8e89-4d7c62839b6e': {
		unit: '',
		expandable: true,
	},
	'4c306338-0d21-4adc-87e1-8f7a9cf19212': {
		unit: '',
		expandable: true,
	},
	'2585b293-3bcd-465d-8168-1a9ed80a252f': {
		unit: '',
		expandable: true,
	},
	'528f0cee-7473-4756-880c-a06bb08e001a': {
		unit: '',
		expandable: true,
	},
	'16328630-21fb-433e-a1fd-85bcb6c71be9': {
		unit: '',
		expandable: true,
	},
	'4af5ce9a-73e4-464a-aaf4-a1307ac51958': {
		unit: '',
		expandable: true,
	},
	'25016594-1600-405f-ba22-1a6d679be6b2': {
		unit: '',
		expandable: true,
	},
	'5cd2ab40-b032-4392-b794-37774bd9cb52': {
		unit: '',
		expandable: true,
	},
	'f8218dfd-4260-4503-a0d9-95331c4979fc': {
		unit: '',
		expandable: true,
	},
	'8ad1519a-ef40-4b02-86b7-80df414d54c4': {
		unit: '',
		expandable: true,
	},
	'47eac850-cb28-4cf1-8b96-3f7591261f36': {
		unit: '',
		expandable: true,
	},
	'54fddcbc-f214-40b5-8949-812c44e54195': {
		unit: '',
		expandable: true,
	},
	'adc858d4-2d61-4cd3-8e28-2a0e94f5ef02': {
		unit: '',
		expandable: true,
	},
	'9212b00a-5a83-42c4-9998-46187a395e24': {
		unit: '',
		expandable: true,
	},
	// WorldPop, GPW
	// END

	//WSF2019
	// START
	'0fed6c38-9f3e-4aed-b0c3-9ec220a0b83e': {
		unit: '',
		expandable: false,
		style: {
			data: {
				definition: {
					rules: [
						{
							styles: [
								{
									color: '#000000',
								},
								{
									attributeKey: 'attr-2',
									attributeValues: {
										Settlement: {
											fill: '#000000',
										},
									},
								},
							],
						},
					],
				},
			},
		},
	},
	'a0c8d936-2e52-4c31-8c55-ffefb1fed8c0': {
		unit: '',
		expandable: false,
		style: {
			data: {
				definition: {
					rules: [
						{
							styles: [
								{
									color: '#000000',
								},
								{
									attributeKey: 'attr-2',
									attributeValues: {
										Settlement: {
											fill: '#000000',
										},
									},
								},
							],
						},
					],
				},
			},
		},
	},
	'10b5b8ca-794d-40d2-961a-a8878cb9ec09': {
		unit: '',
		expandable: false,
		style: {
			data: {
				definition: {
					rules: [
						{
							styles: [
								{
									color: '#000000',
								},
								{
									attributeKey: 'attr-2',
									attributeValues: {
										Settlement: {
											fill: '#000000',
										},
									},
								},
							],
						},
					],
				},
			},
		},
	},
	'ebc4a4df-8677-4eb0-9635-bb79c8e13d1a': {
		unit: '',
		expandable: false,
		style: {
			data: {
				definition: {
					rules: [
						{
							styles: [
								{
									color: '#000000',
								},
								{
									attributeKey: 'attr-2',
									attributeValues: {
										Settlement: {
											fill: '#000000',
										},
									},
								},
							],
						},
					],
				},
			},
		},
	},
	'0ed19a9b-af0b-4209-ae79-f53f6d8ef350': {
		unit: '',
		expandable: false,
		style: {
			data: {
				definition: {
					rules: [
						{
							styles: [
								{
									color: '#000000',
								},
								{
									attributeKey: 'attr-2',
									attributeValues: {
										Settlement: {
											fill: '#000000',
										},
									},
								},
							],
						},
					],
				},
			},
		},
	},
	//WSF2019
	// END
	'f0a971c8-9838-4e01-b82f-4f39d0a5fdf9': {
		unit: '%',
		expandable: true,
	},
	'a3302055-bd7c-4635-9e5c-00356e3423aa': {
		unit: '',
		expandable: true,
		style: {
			data: {
				definition: {
					rules: [
						{
							styles: [
								{
									color: '#000000',
								},
								{
									attributeKey: 'attr-2',
									attributeValues: {
										// FIXME - add class names
										1: {fill: 'rgb(0,197, 255)'},
										2: {fill: 'rgb(0,0,0'},
										3: {fill: 'rgb(255,255, 255)'},
										4: {fill: 'rgb(255,255, 0)'},
										5: {fill: 'rgb(255,170, 0)'},
										6: {fill: 'rgb(255,0, 0)'},
									},
								},
							],
						},
					],
				},
			},
			// attributeValues
			// {
			// },
		},
		//WMS layer TODO add
	},
	'fccef7a8-2563-4a02-8a18-27e05f21ece6': {
		unit: '',
		expandable: false,
		style: {
			data: {
				definition: {
					rules: [
						{
							styles: [
								{
									color: '#000000',
								},
								{
									attributeKey: 'attr-2',
									attributeValues: {
										Settlement: {
											fill: '#e324cd',
										},
									},
								},
							],
						},
					],
				},
			},
		},
	},
	'98888b6c-4987-43f9-8a7c-c691fa32c7e9': {
		unit: '',
		expandable: false,
		style: {
			data: {
				definition: {
					rules: [
						{
							styles: [
								{
									color: '#000000',
								},
								{
									attributeKey: 'attr-2',
									attributeValues: {
										Settlement: {
											fill: '#e324cd',
										},
									},
								},
							],
						},
					],
				},
			},
		},
	},
	'3a2e05e8-ec88-4c0c-acbc-5298ed4b53dc': {
		unit: '',
		expandable: false,
		style: {
			data: {
				definition: {
					rules: [
						{
							styles: [
								{
									color: '#000000',
								},
								{
									attributeKey: 'attr-2',
									attributeValues: {
										Settlement: {
											fill: '#750000',
										},
									},
								},
							],
						},
					],
				},
			},
		},
	},
	'76cb3cc8-7023-43ec-9cfb-145d413f2c12': {
		unit: '',
		expandable: true,
		style: {
			data: {
				definition: {
					rules: [
						{
							styles: [
								{
									color: '#000000',
								},
								{
									attributeKey: 'attr-2',
									attributeValues: {
										1985: {
											fill: '#D50000',
										},
										1986: {
											fill: '#DD2200',
										},
										1987: {
											fill: '#E54500',
										},
										1988: {
											fill: '#EE6700',
										},
										1989: {
											fill: '#F68A00',
										},
										1990: {
											fill: '#FFAD00',
										},
										1991: {
											fill: '#ECBD00',
										},
										1992: {
											fill: '#D9CD00',
										},
										1993: {
											fill: '#C6DE00',
										},
										1994: {
											fill: '#B3EE00',
										},
										1995: {
											fill: '#A1FF00',
										},
										1996: {
											fill: '#80F200',
										},
										1997: {
											fill: '#60E500',
										},
										1998: {
											fill: '#40D800',
										},
										1999: {
											fill: '#20CB00',
										},
										2000: {
											fill: '#00BE00',
										},
										2001: {
											fill: '#00B923',
										},
										2002: {
											fill: '#00B546',
										},
										2003: {
											fill: '#00B069',
										},
										2004: {
											fill: '#00AC8C',
										},
										2005: {
											fill: '#00A8AF',
										},
										2006: {
											fill: '#008AB8',
										},
										2007: {
											fill: '#006CC1',
										},
										2008: {
											fill: '#004ECA',
										},
										2009: {
											fill: '#0030D3',
										},
										2010: {
											fill: '#0012DD',
										},
										2011: {
											fill: '#190ECD',
										},
										2012: {
											fill: '#320ABD',
										},
										2013: {
											fill: '#4B07AD',
										},
										2014: {
											fill: '#64039D',
										},
										2015: {
											fill: '#7E008E',
										},
									},
								},
							],
						},
					],
				},
			},
		},
	},
	'097bff6a-b02e-4403-a84c-def965597bd7': {
		// unit: 'FIXME population?',
		unit: 'Population per square km',
		expandable: true,
	},
	'27d30df2-a988-4854-8e10-d5046292ad38': {
		// unit: 'FIXME population?',
		unit: 'Population per square km',
		expandable: true,
	},
	'71e6ec80-35a1-428d-95b8-6c5ec409eaba': {
		// unit: 'FIXME population?',
		unit: 'Population per square km',
		expandable: true,
	},

	// DHAKA
	'b73c65e8-a61f-4d48-9423-0c8eb9a3a9f4': {
		unit: '',
		expandable: false,
		style: {
			data: {
				definition: {
					rules: [
						{
							styles: [
								{
									color: '#000000',
								},
								{
									attributeKey: 'attr-2',
									attributeValues: {
										// 'Population FIXME': {
										Population: {
											fill: '#730000',
										},
									},
								},
							],
						},
					],
				},
			},
			// attributeValues
			// {
			// },
		},
		//WMS layer TODO add
	},

	'4633b80e-878b-4562-806d-4b2d28c4b935': {
		unit: '',
		expandable: false,
		style: {
			data: {
				definition: {
					rules: [
						{
							styles: [
								{
									color: '#000000',
								},
								{
									attributeKey: 'attr-2',
									attributeValues: {
										// 'Population FIXME': {
										Population: {
											fill: '#ff0000',
										},
									},
								},
							],
						},
					],
				},
			},
			// attributeValues
			// {
			// },
		},
		//WMS layer TODO add
	},
	'ce6cd97d-568f-4469-8b34-57235a4ac2e6': {
		style: {
			data: {
				nameInternal: '2.2',
				nameDisplay: 'Change water classification',
				definition: {
					rules: [
						{
							styles: [
								{},
								{
									attributeKey: 'temporary',
									attributeValues: {
										1: {
											fill: '#33a02c',
											name: 'Gain permanent water',
										},
										2: {
											name: 'Loss permanent water',
											fill: '#a50003',
										},
										3: {
											name: 'Unchanged permanent water',
											fill: '#0000ff',
										},
										4: {
											name: 'Gain seasonal water',
											fill: '#b5e61d',
										},
										5: {
											name: 'Loss seasonal water',
											fill: '#e6a1aa',
										},
										6: {
											name: 'Unchanged seasonal water',
											fill: '#38acff',
										},
										7: {
											name: 'Permanent to seasonal',
											fill: '#ffc90e',
										},
										8: {
											name: 'Seasonal to permanent',
											fill: '#ff7f27',
										},
									},
								},
							],
						},
					],
				},
			},
		},
		expandable: false,
		timelineColor: {
			basic: '#65bdeb',
			active: '#0098e6',
		},
		metadataComponentKey: 'maximumExtent',
	},
	'75590dad-dd34-4050-a500-561ad7ca4605': {
		styleKey: '2958d772-2fd0-486b-9292-dfa6ab896639',
		expandable: false,
		timelineColor: {
			basic: '#65bdeb',
			active: '#0098e6',
		},
		metadataComponentKey: 'minimumExtent',
	},
	'ffcd050c-9b30-4cb4-8a1f-cdfead12f9e5': {
		styleKey: 'eae83a07-c4fd-4269-8846-1e1bc18e1ad8',
		expandable: true,
		timelineColor: {
			basic: '#65bdeb',
			active: '#0098e6',
		},
		metadataComponentKey: 'waterClassification',
	},
	'a3b2b7de-54f7-44f8-8a95-e59bcc16eb76': {
		styleKey: '8c9d0141-32b8-45c3-93fd-8350643f8103',
		comment: 'different style key',
		expandable: false,
		timelineColor: {
			basic: '#65bdeb',
			active: '#0098e6',
		},
		metadataComponentKey: 'waterSeasonality',
	},
	'ef0fa8f2-2c8e-40c4-acca-bf1757199a00': {
		styleKey: '2958d772-2fd0-486b-9292-dfa6ab896639',
		expandable: false,
		timelineColor: {
			basic: '#65bdeb',
			active: '#0098e6',
		},
		metadataComponentKey: 'waterPresence',
	},
	'73fe1a11-cf6a-4f43-b955-185125004c8e': {
		styleKey: 'ef0e6b41-9506-4b1a-84ef-01df835a4477',
		expandable: false,
		unit: '%',
		timelineColor: {
			basic: '#a1dbb4',
			active: '#349453',
		},
		metadataComponentKey: 'changeWaterFrequency',
	},
	'2aa8462d-a6e6-407e-a4d7-c6180ef47223': {
		styleKey: '3fcf6bf4-6733-41d2-8fb0-e64a06b869d5',
		expandable: false,
		timelineColor: {
			basic: '#a1dbb4',
			active: '#349453',
		},
		metadataComponentKey: 'changeMaximumExtent',
	},
	'17263278-a27a-4114-9ff8-5cc7252b2e50': {
		styleKey: '6094ad1d-a815-4a6e-a6ee-32662660a68e',
		expandable: false,
		timelineColor: {
			basic: '#a1dbb4',
			active: '#349453',
		},
		metadataComponentKey: 'changeMinimumExtent',
	},
	'5d8368ba-325c-4639-bda6-cffa017ea012': {
		styleKey: '4707ac97-dbf0-46e6-80fb-b4dddd535b1e',
		expandable: true,
		timelineColor: {
			basic: '#a1dbb4',
			active: '#349453',
		},
		metadataComponentKey: 'changeWaterClassification',
	},
	'a53aed09-ec6b-4fa9-881b-39d96f6915fe': {
		styleKey: '6094ad1d-a815-4a6e-a6ee-32662660a68e',
		expandable: false,
		timelineColor: {
			basic: '#a1dbb4',
			active: '#349453',
		},
		metadataComponentKey: 'changeWaterPresence',
	},

	// GHS population
	// START
	'8a20260b-90ff-4f59-994a-a704e1b9b67f': {
		unit: 'Population per square km',
		expandable: true,
	},
	'413c76e9-a7d3-490c-bea5-a724b936a677': {
		unit: 'Population per square km',
		expandable: true,
	},
	'b5ee3450-6a39-4d83-89ff-4a431c247eaf': {
		unit: 'Population per square km',
		expandable: true,
	},
	'36b1840a-8737-4add-9405-330a295abc91': {
		unit: 'Population per square km',
		expandable: true,
	},
	// GHS population
	// END
};
