import {createSelector} from 'reselect';
import {
	filter as _filter,
	includes as _includes,
	isEmpty as _isEmpty,
} from 'lodash';
import {Select as CommonSelect} from '@gisatcz/ptr-state';

import RouterSelectors from '../../router/selectors';

/**
 * Check if given tag is present in current filter
 * @param state {Object}
 * @param tagKey {string}
 * @return {boolean}
 **/
const isTagPresentInCurrentFilter = createSelector(
	[RouterSelectors.getTags, (state, tagKey) => tagKey],
	(activeTagKeys, tagKey) => {
		return !!_includes(activeTagKeys, tagKey);
	}
);

/**
 * Get all tags representing filter categories
 * @param state {Object}
 * @return {Array | null}
 */
const getCategories = createSelector(
	[
		CommonSelect.tags.getAll,
		state => CommonSelect.app.getConfiguration(state, 'tags.isFilterCategory'),
	],
	(tags, categoryTagKey) => {
		if (tags && categoryTagKey) {
			return _filter(tags, tag => _includes(tag.data.tagKeys, categoryTagKey));
		} else {
			return null;
		}
	}
);

/**
 * Get all product tags
 * @param state {Object}
 * @return {Array | null}
 */
const getProductTags = createSelector(
	[
		CommonSelect.tags.getAll,
		state => CommonSelect.app.getConfiguration(state, 'tags.isProduct'),
	],
	(tags, productTagKey) => {
		if (tags && productTagKey) {
			return _filter(tags, tag => _includes(tag.data.tagKeys, productTagKey));
		} else {
			return null;
		}
	}
);

/**
 * Get product tags grouped by category
 * @param state {Object}
 * @return {Array | null}
 */
const getProductTagsGroupedByCategory = createSelector(
	[getCategories, getProductTags],
	(categoryTags, productTags) => {
		if (categoryTags && productTags) {
			let tagsGroupedByCategory = {};

			categoryTags.forEach(categoryTag => {
				const {key, data} = categoryTag;
				if (!tagsGroupedByCategory[key]) {
					tagsGroupedByCategory[key] = {
						key,
						data,
						tags: [],
					};
				}

				productTags.forEach(productTag => {
					if (_includes(productTag.data.tagKeys, categoryTag.key)) {
						tagsGroupedByCategory[key].tags.push(productTag);
					}
				});

				if (!tagsGroupedByCategory[key].tags.length) {
					delete tagsGroupedByCategory[key];
				}
			});

			if (_isEmpty(tagsGroupedByCategory)) {
				return null;
			} else {
				return Object.values(tagsGroupedByCategory);
			}
		} else {
			return null;
		}
	}
);

/**
 * Get primary item tags
 * @param state {Object}
 * @param primaryItemTagKey {string}
 * @return {Array | null}
 */
const getPrimaryItems = createSelector(
	[
		CommonSelect.tags.getAll,
		state =>
			CommonSelect.app.getConfiguration(state, 'tags.isFilterPrimaryItem'),
	],
	(tags, filterPrimaryTagTagKey) => {
		if (tags && filterPrimaryTagTagKey) {
			return _filter(tags, tag =>
				_includes(tag.data.tagKeys, filterPrimaryTagTagKey)
			);
		} else {
			return null;
		}
	}
);

export default {
	getCategories,
	getPrimaryItems,
	getProductTagsGroupedByCategory,

	isTagPresentInCurrentFilter,
};
