import {createSelector} from 'reselect';
import {Select as CommonSelect} from '@gisatcz/ptr-state';

import VisatTagsSelectors from './tags/selectors';
import ProductFilterSelectors from './productFilter/selectors';
import DatasetsSelectors from './datasets/selectors';
import CollectionsSelectors from './collections/selectors';
import ProductViewerSelectors from './productViewer/selectors';
import legendConfiguration from '../../data/legends';

/**
 * Return true if view key is part of active view
 * @param state {Object}
 * @param viewKey {string}
 * @return {boolean}
 **/
const isViewPartOfActiveView = createSelector(
	[CommonSelect.views.getActive, (state, viewKey) => viewKey],
	(activeView, viewKey) => {
		return !!activeView.data?.state?.views?.byKey?.[viewKey];
	}
);

const getLayerTemplateConfigurations = createSelector([], () => {
	return legendConfiguration;
});

export default {
	collections: CollectionsSelectors,
	datasets: DatasetsSelectors,
	productFilter: ProductFilterSelectors,
	tags: VisatTagsSelectors,
	productViewer: ProductViewerSelectors,
	getLayerTemplateConfigurations,
	isViewPartOfActiveView,
};
