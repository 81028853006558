export default [
	{
		key: 'unhab',
		data: {
			title: 'Urban Expansion Insight',
			description:
				'Application exploits the opportunities offered by the Urban TEP, EDC technologies and the World Settlement Footprint (WSF) datasets onboarded. Implemented by GISAT and DLR, the application directly contributes to production of high-quality city data on SDG indicator 11.3.1 across all world regions, and to prepare the ground for other related indicators within the NUA monitoring framework. Granted by ESA Earthnet funding to support collaboration with UN Habitat on Sustainable Development (SDG) Goal 11 (Sustainable cities and communities) and the New Urban Agenda (NUA) support. Moreover, the important goal is to support national and local city authorities from developing countries to embrace EO technology in their work.',
			// url: ' https://visat-sdg-dev.gisat.cz',
			image: {
				key: 'unhab',
				alt: 'unhab',
			},
			tagKeys: [
				'1ab63e38-1acb-4b30-b6cd-4aca8d09194c',
				'8fd34a09-bc19-4d97-9d4e-749ac2e8d735',
				'fa9a5621-9b73-447f-8163-18a2aaa093e9',
			],
			internal: true,
		},
	},
	{
		key: 'sdg',
		data: {
			title: 'SDG Explorer Europe',
			description:
				'As global urbanisation continues to rise, effective urban planning and management become crucial. The UN SDG 11 goals targets urban sustainability by focusing on the relationship between land consumption rate and population growth. Earth Observation (EO) technology has revolutionized urban studies by providing accurate and harmonised means for urban expansion monitoring on a global scale. However, extracting valuable insights from the vast amount of available data remains challenging. The SDG 11 Explorer Europe offers a user-friendly tool that allows easy exploration and analysis of the land consumption part of the story. Users can access, explore, and compare land consumption trends in different locations, utilizing various EO-based datasets.',
			// url: ' https://visat-sdg-dev.gisat.cz',
			image: {
				key: 'sdg',
				alt: 'sdg',
			},
			tagKeys: [
				'1ab63e38-1acb-4b30-b6cd-4aca8d09194c',
				'8fd34a09-bc19-4d97-9d4e-749ac2e8d735',
				'fa9a5621-9b73-447f-8163-18a2aaa093e9',
			],
			internal: true,
		},
	},
	{
		key: 'citydatacube',
		data: {
			title: 'CityDataCube Explorer',
			description:
				'Open EO resources available for cities are too heterogenous and complex to effectively support city monitoring and decisison making.  CityDataCube organises urban data to facilitate easy access to a vast amount of EO and non-EO data in an analysis- ready fashion. Provided by Euro Data Cube (EDC) services, it organizes urban data for easy analysis, offering xarray grids in common spatio-temporal coordinate systems via cloud infrastructures. CityDataCube Explorer demonstrates integration of CityDataCube resources within VISAT to give instantaneous visual access to whole data cubes and fosters an explorative approach to large data sets with outstanding performance and usability. Master complex data with ease.',
			// url: 'https://esautepunhabitat-dev.gisat.cz/app?viewKey=439cfb29-adc1-487e-9d4f-ff5e87755b96',
			image: {
				key: 'citydatacube',
				alt: 'citydatacube',
			},
			tagKeys: [
				'4b333d17-1b37-4081-b90e-77e3e132f03e',
				'1ab63e38-1acb-4b30-b6cd-4aca8d09194c',
				'8fd34a09-bc19-4d97-9d4e-749ac2e8d735',
			],
			internal: true,
		},
	},
	// {
	// 	key: 'nordmap',
	// 	data: {
	// 		title: 'Regional Statistics Explorer',
	// 		description:
	// 			'Explore richness of regional statistics using free to use online mapping tool. Working with regional planning or just interested in regional and municipal differences between when it comes to ageing population, labour force or even gender ratio? Create maps using harmonised regionally curated data, focus and adjust them to your needs with just a few clicks. Share it, save it or use it any way you like.',
	// 		url: ' http://nordmap.se/',
	// 		image: {
	// 			key: 'nordmap',
	// 			alt: 'nordmap',
	// 		},
	// 		tagKeys: [
	// 			'1ab63e38-1acb-4b30-b6cd-4aca8d09194c',
	// 			'fa9a5621-9b73-447f-8163-18a2aaa093e9',
	// 		],
	// 	},
	// },
	{
		key: 'insar-samoa',
		data: {
			title: 'Ground Motions Patterns Explorer',
			description:
				'Visualise patterns of the average ground motion velocities. Multitemporal PSI data explored in easy-to-use web tool for ground motions and exposure assessment. Synthesize from huge cloud of InSAR-based ground deformation measurement points into land subsidence exposure assessment for your units or features of interest. Quickly, efficiently and transparently.',
			url: 'https://eo4sd-drr.gisat.cz/samoa/',
			image: {
				key: 'insar-samoa',
				alt: 'insarSamoa',
			},
			tagKeys: [
				'fa9a5621-9b73-447f-8163-18a2aaa093e9',
				'e08ded4a-9f1c-4aa2-bed7-e5620af10c33',
			],
		},
	},
	{
		key: 'scudeoStories-globalWsf',
		data: {
			title: 'Global Urban Growth Dynamics Monitoring',
			description:
				'The urbanized World is our playground. Facing global massive urbanization trends in climate change context, urban expansion needs to be monitored to ensure it proceeds on a sustainable basis, does not impair or overexploit environmental resources, nor worsen the quality and life and safety of the urban population. Nowadays, EO based global products are available for urban studies to be done in rich spatial-temporal context, quickly and accurately.',
			url: 'https://urban-tep.eu/visat/scudeoStories19/globalWsf',
			image: {
				key: 'scudeoStories-globalWsf',
				alt: 'globalWsf',
			},
			tagKeys: [
				'1ab63e38-1acb-4b30-b6cd-4aca8d09194c',
				'4b333d17-1b37-4081-b90e-77e3e132f03e',
				'8fd34a09-bc19-4d97-9d4e-749ac2e8d735',
				'e08ded4a-9f1c-4aa2-bed7-e5620af10c33',
			],
		},
	},
	{
		key: 'scudeoStories-greenAreas',
		data: {
			title: 'Mapping and monitoring of urban green areas',
			description:
				'EO4SD-Urban provides a range of tailored products derived by advanced analysis of recent very high resolution satellite imagery to describe distribution of urban green areas in the city, their structure and typology and evolution over the time. This presentation brings simple but powerful examples of mapping and statistical outputs derived directly from EO4SD-Urban’s Urban Green baseline products, which provide means for high level comparative analysis between different cities.',
			url: 'https://urban-tep.eu/visat/scudeoStories19/greenAreas',
			image: {
				key: 'scudeoStories-greenAreas',
				alt: 'greenAreas',
			},
			tagKeys: [
				'4b333d17-1b37-4081-b90e-77e3e132f03e',
				'8fd34a09-bc19-4d97-9d4e-749ac2e8d735',
				'e08ded4a-9f1c-4aa2-bed7-e5620af10c33',
			],
		},
	},
	{
		key: 'scudeoStories-landAssets',
		data: {
			title: 'City Land Assets Structure and Evolution',
			description:
				'Land is a non-renewable resource and its quantity and quality play a vital role in the development of a city. Land structure and spatial-temporal patterns to a large extent influence city livability and resilience as well as determine physical constraints, opportunities and potential for further development.',
			url: 'https://urban-tep.eu/visat/scudeoStories19/landAssetsStructure',
			image: {
				key: 'scudeoStories-landAssets',
				alt: 'landAssets',
			},
			tagKeys: [
				'4b333d17-1b37-4081-b90e-77e3e132f03e',
				'8fd34a09-bc19-4d97-9d4e-749ac2e8d735',
				'e08ded4a-9f1c-4aa2-bed7-e5620af10c33',
			],
		},
	},
];
