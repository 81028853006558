import {commonActionTypes} from '@gisatcz/ptr-state';
import {utils} from '@gisatcz/ptr-utils';

const specificActionTypes = utils.deepKeyMirror({
	VISAT: {},
	SPECIFIC: {
		VISAT_APPLICATIONS: {
			ADD: null,
		},
	},
});

export default {
	...commonActionTypes,
	...specificActionTypes,
};
