import PropTypes from 'prop-types';
import './style.scss';

const IndexCenteredWrap = ({children}) => {
	return <div className="visat-IndexCenteredWrap">{children}</div>;
};

IndexCenteredWrap.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]).isRequired,
};

export default IndexCenteredWrap;
