// supported query strings
export default {
	darkMode: 'darkMode',
	tags: 'tags',
	viewKey: 'viewKey',
	detailsViewKey: 'detailsViewKey',
	detailsView: 'detailsView',
};

export const screens = {
	productViewerScreen: {
		key: 'productViewerScreen',
		represents: ['datasetsMap', 'collectionsMap'],
	},
	homeScreen: {
		key: 'homeScreen',
		represents: ['homeScreen'],
	},
};

export const defaultScreen = screens['homeScreen'].key;
