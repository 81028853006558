import PropTypes from 'prop-types';
import CatalogFilterCategorySection from './CatalogFilterCategorySection';
import CatalogFilterPrimaryItem from './CatalogFilterPrimaryItem';
import './style.scss';

const CatalogFilter = ({
	tagsByCategory,
	primaryItems,
	favouritesTagKey,
	recommendedTagKey,
}) => {
	let categoriesContent;
	let primaryItemsContent;

	if (tagsByCategory?.length) {
		categoriesContent = tagsByCategory.map(category => (
			<CatalogFilterCategorySection
				key={category.key}
				categoryTagKey={category.key}
				title={category.data?.nameDisplay}
				tags={category.tags}
			/>
		));
	} else {
		categoriesContent = (
			<>
				<CatalogFilterCategorySection />
				<CatalogFilterCategorySection />
				<CatalogFilterCategorySection />
			</>
		);
	}

	if (primaryItems?.length) {
		primaryItemsContent = primaryItems.map(primaryItemTag => {
			if (primaryItemTag?.key === recommendedTagKey) {
				return (
					<CatalogFilterPrimaryItem
						name={primaryItemTag?.data?.nameDisplay}
						icon="ri-thumb-up"
						key={primaryItemTag?.key}
						tagKey={primaryItemTag?.key}
					/>
				);
			} else if (primaryItemTag?.key === favouritesTagKey) {
				return (
					<CatalogFilterPrimaryItem
						name={primaryItemTag?.data?.nameDisplay}
						icon="ri-star"
						disabled
						key={primaryItemTag?.key}
						tagKey={primaryItemTag?.key}
					/>
				);
			} else {
				return (
					<CatalogFilterPrimaryItem
						name={primaryItemTag?.data?.nameDisplay}
						key={primaryItemTag?.key}
						tagKey={primaryItemTag?.key}
					/>
				);
			}
		});
	} else {
		primaryItemsContent = (
			<>
				<CatalogFilterPrimaryItem placeholder />
				<CatalogFilterPrimaryItem placeholder />
			</>
		);
	}

	return (
		<div className="visat-CatalogFilter">
			<div className="visat-CatalogFilterPrimaryItems">
				{primaryItemsContent}
			</div>
			{categoriesContent}
		</div>
	);
};

CatalogFilter.propTypes = {
	tagsByCategory: PropTypes.array,
	primaryItems: PropTypes.array,
	favouritesTagKey: PropTypes.string,
	recommendedTagKey: PropTypes.string,
};

export default CatalogFilter;
