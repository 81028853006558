import classnames from 'classnames';
import PropTypes from 'prop-types';

const ProductCardDescription = ({className, children, large, placeholder}) => {
	const classes = classnames(
		'ptr-ProductCardDescription',
		{
			'is-large': large,
			'is-placeholder': placeholder,
		},
		className
	);

	return <p className={classes}>{children}</p>;
};

ProductCardDescription.propTypes = {
	className: PropTypes.string,
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),
	large: PropTypes.bool,
	placeholder: PropTypes.bool,
};

export default ProductCardDescription;
