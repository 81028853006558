import {createSelector} from 'reselect';
import {
	difference as _difference,
	filter as _filter,
	includes as _includes,
} from 'lodash';
import {Select as CommonSelect} from '@gisatcz/ptr-state';

import RouterSelectors from '../../router/selectors';

/**
 * Return all datasets
 * @param state {Object}
 * @return {Array | null}
 */
const getAll = createSelector(
	[
		state => CommonSelect.app.getConfiguration(state, 'tags.isDataset'),
		CommonSelect.views.getAll,
	],
	(isDatasetTagKey, views) => {
		if (views?.length && isDatasetTagKey) {
			return _filter(views, view =>
				_includes(view.data?.tagKeys, isDatasetTagKey)
			);
		} else {
			return null;
		}
	}
);

/**
 * Get datasets which fit current dataset filter
 * @param state {Object}
 * @return {Array | null}
 **/
const getForCurrentFilter = createSelector(
	[RouterSelectors.getTags, getAll],
	(activeFilterTagKeys, datasets) => {
		if (!activeFilterTagKeys?.length) {
			return datasets;
		} else {
			return _filter(
				datasets,
				dataset =>
					_difference(activeFilterTagKeys, dataset.data?.tagKeys)?.length === 0
			);
		}
	}
);

/**
 * Get datasets filter
 * @param state {Object}
 * @return {Array | null}
 **/
const getViewFilterByTags = createSelector(
	[RouterSelectors.getTags],
	activeFilterTagKeys => {
		return {
			filterByActive: {application: true},
			filter: {
				...(activeFilterTagKeys?.length
					? {
							tagKeys: {
								in: [
									...(activeFilterTagKeys?.length ? activeFilterTagKeys : []),
								],
							},
					  }
					: {}),
			},
			order: [['nameDisplay', 'ascending']],
			start: 1,
			length: 1000,
		};
	}
);

/**
 * Return true if dataset view key is active view key
 * @param state {Object}
 * @param viewKey {string}
 * @return {boolean}
 **/
const isActive = createSelector(
	[CommonSelect.views.getActiveKey, (state, viewKey) => viewKey],
	(activeViewKey, viewKey) => {
		return activeViewKey === viewKey;
	}
);

export default {
	getAll,
	getForCurrentFilter,
	getViewFilterByTags,

	isActive,
};
