import PropTypes from 'prop-types';
import classnames from 'classnames';
import {
	ExpandableLabel,
	ExpandableLabelBody,
	ExpandableLabelHeader,
	OpacitySlider,
	Icon,
} from '@gisatcz/visat-components';

import './style.scss';
import {useEffect} from 'react';

const LayerLabel = ({
	layerState,
	layerTemplate,
	zIndex,
	onOpacityChange,
	onRemove,
	onMount,
	onUnmount,
}) => {
	useEffect(() => {
		onMount(layerState.layerTemplateKey);
		return onUnmount();
	}, []);

	return (
		<>
			<ExpandableLabel floating className="visat-LayerLabel" zIndex={zIndex}>
				<ExpandableLabelHeader wholeInteractive>
					<LayerLabelHeader
						title={layerTemplate?.data?.nameDisplay || 'Layer title'}
					/>
				</ExpandableLabelHeader>
				<ExpandableLabelBody height={4}>
					<div className="visat-LayerLabelBody">
						<div>
							<LayerLabelBodyItem title="Set opacity">
								<OpacitySlider
									value={
										layerState?.opacity >= 0 ? layerState?.opacity * 100 : 100
									}
									onChange={onOpacityChange}
								/>
							</LayerLabelBodyItem>
							<LayerLabelBodyItem onClick={onRemove} title="Remove layer">
								<Icon icon="ri-close" />
							</LayerLabelBodyItem>
						</div>
					</div>
				</ExpandableLabelBody>
			</ExpandableLabel>
		</>
	);
};

LayerLabel.propTypes = {
	layerState: PropTypes.object,
	layerTemplate: PropTypes.object,
	period: PropTypes.object,
	zIndex: PropTypes.number,
	onOpacityChange: PropTypes.func,
	onRemove: PropTypes.func,
	onMount: PropTypes.func,
	onUnmount: PropTypes.func,
};

const LayerLabelHeader = ({title}) => {
	return (
		<div className="visat-LayerLabelHeader">
			<div className="visat-LayerLabelHeader-title">{title}</div>
		</div>
	);
};

LayerLabelHeader.propTypes = {
	title: PropTypes.string,
};

const LayerLabelBodyItem = ({
	title,
	url,
	isLink,
	dangerous,
	disabled,
	onClick,
	children,
}) => {
	const hoverable = !disabled && (!!onClick || isLink);
	const classes = classnames('visat-LayerLabelBodyItem', {
		'is-hoverable': hoverable,
		'is-dangerous': dangerous,
		'is-disabled': disabled,
	});

	const handleKeyDown = event => {
		if (event.key === 'Enter') {
			onClick();
		}
	};

	if (isLink) {
		return (
			<a className={classes} href={url}>
				<div className="visat-LayerLabelBodyItem-title">{title}</div>
				<div className="visat-LayerLabelBodyItem-tool">{children}</div>
			</a>
		);
	} else {
		return (
			<div
				className={classes}
				onClick={onClick}
				tabIndex={hoverable ? 0 : -1}
				onKeyDown={handleKeyDown}
			>
				<div className="visat-LayerLabelBodyItem-title">{title}</div>
				<div className="visat-LayerLabelBodyItem-tool">{children}</div>
			</div>
		);
	}
};

LayerLabelBodyItem.propTypes = {
	title: PropTypes.string,
	dangerous: PropTypes.bool,
	disabled: PropTypes.bool,
	isLink: PropTypes.bool,
	onClick: PropTypes.func,
	url: PropTypes.string,
	children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

export default LayerLabel;
