import PropTypes from 'prop-types';
import {useEffect} from 'react';
import ProductViewerHeader from './ProductViewerHeader';
import Map from './Map';
import Timeline from '../Timeline';

import './style.scss';

const ProductViewerScreen = ({viewKey, onViewKeyChanged, onUmnount}) => {
	useEffect(() => {
		return onUmnount;
	}, []);

	useEffect(() => {
		onViewKeyChanged(viewKey);
	}, [viewKey]);

	return (
		<div className={'visat-ProductViewerScreen'}>
			<ProductViewerHeader />
			<div className="visat-ProductViewerScreen-Content">
				<Map />
				<Timeline />
			</div>
		</div>
	);
};

ProductViewerScreen.propTypes = {
	onViewKeyChanged: PropTypes.func,
	onUmnount: PropTypes.func,
	viewKey: PropTypes.string,
};

export default ProductViewerScreen;
