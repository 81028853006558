import classnames from 'classnames';
import PropTypes from 'prop-types';
import {Icon} from '@gisatcz/visat-components';

const AppPreviewTools = ({className, url, internal}) => {
	const classes = classnames('visat-AppPreviewTools', {}, className);

	return (
		<div className={classes}>
			<a
				className="ptr-button primary"
				href={url}
				target={internal ? '' : '_blank'}
				rel="noreferrer noopener"
			>
				<div className="ptr-button-caption">Explore</div>
				{!internal ? <Icon icon="external-link" /> : null}
			</a>
		</div>
	);
};

AppPreviewTools.propTypes = {
	className: PropTypes.string,
	url: PropTypes.string,
	internal: PropTypes.bool,
};

export default AppPreviewTools;
