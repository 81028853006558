import {connect} from '@gisatcz/ptr-state';
import {utils} from '@gisatcz/ptr-utils';
import Action from '../../../../state/Action';
import {collectionsTagsIndex} from '../../../../constants/app';
import Presentation from './presentation';

const mapStateToProps = () => {
	return {};
};

const mapDispatchToProps = () => {
	const componentId = `collectionsCatalog_tags_${utils.uuid()}`;
	return dispatch => {
		return {
			onMount: () => {
				dispatch(
					Action.tags.useIndexed(
						collectionsTagsIndex.filterByActive,
						collectionsTagsIndex.filter,
						collectionsTagsIndex.order,
						collectionsTagsIndex.start,
						collectionsTagsIndex.length,
						componentId
					)
				);
			},
			onUnmount: () => {
				dispatch(Action.tags.useIndexedClear(componentId));
			},
		};
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
