import PropTypes from 'prop-types';
import Link from '../../common/Link';
import {useState} from 'react';
import {IconTool, Icon} from '@gisatcz/visat-components';
import ModalWindow from '../../common/ModalWindow';
import {getRouter} from '../../../router';
import ProductDetails from '../../common/ProductDetails';
import AppSettings from '../../IndexScreen/IndexHeader/IndexTools/AppSettings';
import DatasetCatalog from '../../IndexScreen/IndexBody/DatasetCatalog';
import './style.scss';
import Tooltip from '../../common/Tooltip';

const ProductViewerHeader = ({
	parentPath,
	view,
	closeDetails,
	detailsOpen,
	openDetails,
}) => {
	const router = getRouter();
	const [isCatalogOpen, setCatalogOpen] = useState(false);

	return (
		<>
			<div className="visat-ProductViewerHeader">
				<div className="visat-ProductViewerTitle">
					<h1>
						<Link
							name={parentPath}
							router={router}
							recoverParamsFilter={['darkMode']}
							recoverParams={true}
							paramsFilter={['viewKey']}
						>
							visat
						</Link>
					</h1>
					<div className="visat-ProductViewerTitle-divider">/</div>
					<div className="visat-ProductViewerTitle-type">
						<Link
							name={parentPath}
							router={router}
							recoverParamsFilter={['darkMode']}
							recoverParams={true}
							paramsFilter={['viewKey']}
						>
							<Icon icon={`ri-${parentPath}`} />
							<span>{parentPath}</span>
						</Link>
					</div>
					<div className="visat-ProductViewerTitle-divider">/</div>
					<h2
						className="visat-ProductViewerProductTitle"
						title="World settlement footprint (20 m resolution)"
					>
						{view?.data?.nameDisplay}
					</h2>
					<IconTool
						className="visat-ProductViewerProductTool"
						// disabled
						icon="ri-info"
						onClick={() => openDetails(view?.key)}
						tooltip={{
							text: 'Details',
							id: 'productViewer_details',
							position: 'bottom',
							component: Tooltip,
						}}
					/>
					{parentPath === 'datasets' ? (
						<IconTool
							className="visat-ProductViewerProductTool"
							// disabled
							icon="ri-add"
							onClick={() => setCatalogOpen(true)}
							tooltip={{
								text: 'Add more dataset',
								id: 'productViewer_addMoreDataset',
								position: 'bottom',
								component: Tooltip,
							}}
						/>
					) : null}
				</div>
				<div className="visat-ProductViewerTools">
					<div className="visat-ProductViewerTools-group">
						<IconTool disabled icon="ri-share" />
						<AppSettings />
						<IconTool disabled icon="ri-user" />
					</div>
					{/*<div className="visat-ProductViewerTools-group">*/}
					{/*	<IconTool disabled icon="ri-chevron-up" />*/}
					{/*</div>*/}
				</div>
			</div>
			<ModalWindow isOpen={detailsOpen} onClose={closeDetails} className="">
				<ProductDetails
					viewKey={view?.key}
					onClose={closeDetails}
					view={view}
				/>
			</ModalWindow>
			{parentPath === 'datasets' ? (
				<ModalWindow
					isOpen={isCatalogOpen}
					onClose={() => setCatalogOpen(false)}
					className="visat-AddDatasetModal"
				>
					<h2 className="visat-AddDatasetModal-title">Add dataset</h2>
					<div className="visat-AddDatasetModal-content">
						<DatasetCatalog addDatasetMode />
					</div>
				</ModalWindow>
			) : null}
		</>
	);
};

ProductViewerHeader.propTypes = {
	closeDetails: PropTypes.func,
	detailsOpen: PropTypes.bool,
	openDetails: PropTypes.func,
	parentPath: PropTypes.string,
	view: PropTypes.object,
};

export default ProductViewerHeader;
