import PropTypes from 'prop-types';
import {IconTool} from '@gisatcz/visat-components';
import Tooltip from '../../../../common/Tooltip';

const AppSettings = ({onAppModeChange, small, isDark}) => {
	return (
		<IconTool
			icon="ri-dark-mode"
			onClick={() => onAppModeChange(!isDark)}
			active={isDark}
			small={small}
			tooltip={{text: 'Dark mode', position: 'bottom', component: Tooltip}}
		/>
	);
};

AppSettings.propTypes = {
	small: PropTypes.bool,
	isDark: PropTypes.bool,
	onAppModeChange: PropTypes.func,
};

export default AppSettings;
